import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryCardComponent } from './category-card.component';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [CategoryCardComponent],
  imports: [CommonModule, RouterModule, LazyLoadImageModule],
  exports: [CategoryCardComponent],
})
export class CategoryCardModule {}
