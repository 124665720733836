import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreCardComponent } from './store-card.component';
import { FollowModule } from '../follow/follow.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [StoreCardComponent],
  imports: [
    CommonModule,
    FollowModule,
    RouterModule,
    LazyLoadImageModule,
    SharedModule,
  ],
  exports: [StoreCardComponent],
})
export class StoreCardModule {}
