import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { SearchService } from './search.service';
import { SelectModule } from '../../domain/select/select.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { OffersCardModule } from '../../domain/offers-card/offers-card.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    SelectModule,
    NgxMasonryModule,
    OffersCardModule,
    InfiniteScrollModule,
  ],
  exports: [SearchComponent],
  providers: [SearchService],
})
export class SearchModule {}
