import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LoaderService } from './shared/services/loader.service';
import { ProductModalService } from './shared/services/product-modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public loader: LoaderService,
    private router: Router,
    private productModal: ProductModalService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loader.startLoading();
        }
        if (event instanceof NavigationEnd) {
          this.loader.finishLoading();
        }

        if (event instanceof NavigationCancel) {
          this.loader.finishLoading();
        }

        if (event instanceof NavigationError) {
          this.loader.finishLoading();
        }

        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });
    }
  }
}
