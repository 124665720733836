import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { CategoryModel } from '../../shared/models/category.model';
import { CategoryService } from '../../shared/services/category.service';
import { BannerService } from '../../shared/services/banner.service';

// TODO: Change any to banner model
interface CategoriesResolvingData {
  banners: any[];
  categories: CategoryModel[];
}

@Injectable({ providedIn: 'root' })
export class CategoriesResolver implements Resolve<CategoriesResolvingData> {
  constructor(
    private categoryService: CategoryService,
    private bannerService: BannerService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return forkJoin({
      categories: this.categoryService.getAllCategories(),
      banners: this.bannerService.getBanners(),
    });
  }
}
