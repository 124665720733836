import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { CategoryService } from '../../shared/services/category.service';
import { StoreService } from '../../shared/services/store.service';
import { BannerService } from '../../shared/services/banner.service';
import { ProductsService } from '../../shared/services/products.service';
import { WatchListService } from '../../shared/services/watch-list.service';
import { CartService } from '../../shared/services/cart.service';

@Injectable({ providedIn: 'root' })
export class HomeResolver implements Resolve<any> {
  constructor(
    private categoryService: CategoryService,
    private storeService: StoreService,
    private bannerService: BannerService,
    private productsService: ProductsService,
    private watchListService: WatchListService,
    private cartService: CartService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return forkJoin({
      stores: this.storeService.getStores(),
      categories: this.categoryService.getAllCategories(),
      banners: this.bannerService.getBanners(),
      popular: this.productsService.getPopularProducts(),
      comparedStores: this.storeService.getComparedStores(),
      watchedStores: this.watchListService.preloadStores(),
      watchedProducts: this.watchListService.preloadProducts(),
      cart: this.cartService.preloadCart(),
      updatedDate: this.storeService.getUpdatedDate(),
    });
  }
}
