import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowComponent } from './follow.component';

@NgModule({
  declarations: [FollowComponent],
  imports: [CommonModule],
  exports: [FollowComponent],
})
export class FollowModule {}
