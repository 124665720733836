import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CityService } from './city.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProductModel } from '../models/product.model';
import { SortBy } from '../enums/sort-by.enum';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient, private cityService: CityService) {}

  // TODO: Change any to model type
  public getPopularProducts() {
    return this.http
      .get(`/api/v2/product-popular/city/${this.cityService.getId()}`)
      .pipe(map((json: any) => json.result));
  }

  // TODO: Change any to model type
  public getProductsByCategory(
    categoryId: number,
    page: number = 1,
    sortBy: SortBy = SortBy.POPULAR,
  ): Observable<any> {
    return this.http
      .get<any>(
        `/api/v2/category/${categoryId}/city/${this.cityService.getId()}/products`,
        {
          params: {
            pageNumber: page.toString(),
            sortBy: sortBy.toString(),
          },
        },
      )
      .pipe(map((json) => json.result?.products || []));
  }

  public getProducts(
    storeId: number,
    page: number,
    categoryId: number | null,
    sortBy: SortBy = SortBy.POPULAR,
  ): Observable<ProductModel[] | undefined> {
    const cityId = this.cityService.getId();
    return this.http
      .get<any>(
        categoryId == null
          ? `/api/v2/shop/${storeId}/city/${cityId}/products`
          : `/api/v2/shop/${storeId}/city/${cityId}/category/${categoryId}/products`,
        {
          params: {
            pageNumber: page.toString(),
            sortBy: sortBy.toString(),
          },
        },
      )
      .pipe(map((json) => json.result?.products || []));
  }
}
