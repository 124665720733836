import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { ProductModel } from '../../shared/models/product.model';
import { StoreService } from './store.service';
import { StoreService as StoresService } from '../../shared/services/store.service';
import { ProductsService } from '../../shared/services/products.service';
import { CategoryService } from '../../shared/services/category.service';
import { WatchListService } from '../../shared/services/watch-list.service';
import { CartService } from '../../shared/services/cart.service';

interface StoreResolvingData {
  products: ProductModel[];
}

@Injectable({ providedIn: 'root' })
export class StoreResolver implements Resolve<StoreResolvingData> {
  constructor(
    private storeService: StoreService,
    private storesService: StoresService,
    private categories: CategoryService,
    private products: ProductsService,
    private watchListService: WatchListService,
    private cartService: CartService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    const storeId = +route.paramMap.get('id');
    return forkJoin({
      categories: this.categories.getStoreCategories(storeId),
      products: this.products.getProducts(
        storeId,
        1,
        this.storeService.categoryId,
        this.storeService.sortBy,
      ),
      store: this.storesService.getStoreById(storeId),
      watchedProducts: this.watchListService.preloadProducts(),
      cart: this.cartService.preloadCart(),
    });
  }
}
