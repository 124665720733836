import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loading = new BehaviorSubject<boolean>(true);

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    if (isPlatformServer(this.platformId)) {
      this.loading.next(false);
    }
  }

  public startLoading(): void {
    this.loading.next(true);
  }

  public finishLoading(): void {
    this.loading.next(false);
  }

  public get isLoading$(): Observable<boolean> {
    return this.loading.asObservable();
  }
}
