<section class="offer-card" (click)="openModal(getShopRelationId())">
  <div class="offer-card__info info">
    <div class="info__market">
      <img
        *ngIf="isImageExist()"
        [src]="getShopImageUrl()"
        class="info__market-logo"
      />
      <span class="info__market-title">{{ shop.name }}</span>
    </div>
    <div
      *ngIf="
        isValid(shop.regularPrice) && isValid(shop.offerPrice);
        else elseBlock
      "
      class="info__price"
    >
      <span class="info__price-old">{{ shop.regularPrice }} р</span>
      <span class="info__price-discount">{{ shop.offerPrice }} р</span>
    </div>

    <ng-template #elseBlock>
      <div class="info__price">
        <span *ngIf="isValid(shop.regularPrice)" class="info__price-discount"
          >{{ shop.regularPrice }} р</span
        >
        <span *ngIf="isValid(shop.offerPrice)" class="info__price-discount"
          >{{ shop.offerPrice }} р</span
        >
      </div>
    </ng-template>
  </div>
  <clicker
    [shop]="validStore"
    [product]="validProduct"
    class="offer-card__add-btn"
  ></clicker>
</section>
