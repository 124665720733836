import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMasonryOptions } from 'ngx-masonry';
import { Paths } from '../../../shared/enums/paths.enum';
import { PathsRus } from '../../../shared/enums/paths-rus.enum';

@Component({
  selector: 'watched-products',
  templateUrl: './watched-products.component.html',
  styleUrls: ['./watched-products.component.scss'],
})
export class WatchedProductsComponent implements OnInit {
  public products;
  public masonryOptions: NgxMasonryOptions = {
    gutter: 16,
  };
  public readonly paths = Paths;
  public readonly pathsRus = PathsRus;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.parent.data.subscribe((data) => {
      this.products = data.data.products;
    });
  }

  public async onToggle(event) {
    switch (event) {
      case 0:
        await this.router.navigate([
          '/',
          this.paths.PROFILE,
          this.paths.PROFILE_ALL,
        ]);
        break;
      case 1:
        await this.router.navigate([
          '/',
          this.paths.PROFILE,
          this.paths.PROFILE_WATCH_PRODUCTS,
        ]);
        break;
      case 2:
        await this.router.navigate([
          '/',
          this.paths.PROFILE,
          this.paths.PROFILE_WATCH_STORES,
        ]);
        break;
    }
  }
}
