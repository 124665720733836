<div class="comparison-card" *ngIf="product != null">
  <img
    class="comparison-card__image"
    src="/assets/images/preload.png"
    [lazyLoad]="'http://supercheck.by' + product.imageUrl"
    [offset]="100"
    [alt]="product.name"
  />
  <div class="comparison-card__info">
    <p class="comparison-card__title">{{ product.name }}</p>
    <p class="comparison-card__subtitle">{{ product.volume }}</p>
  </div>
  <div
    [style.opacity]="firstProduct != null ? 1 : 0"
    class="comparison-card__price hl hl--rotated hl--yellow"
    (click)="openModal(firstProduct?.productShopRelationId, firstShopId)"
  >
    <span class="hl__text">{{ firstProduct?.regularPrice }}р</span>
  </div>
  <div
    [style.opacity]="secondProduct != null ? 1 : 0"
    class="comparison-card__price hl hl--rotated hl--orange"
    (click)="openModal(secondProduct?.productShopRelationId, secondShopId)"
  >
    <span class="hl__text">{{ secondProduct?.regularPrice }}р</span>
  </div>
</div>
