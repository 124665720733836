import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card.component';
import { FollowModule } from '../follow/follow.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AddBtnModule } from '../add-btn/add-btn.module';
import { ClickerModule } from '../clicker/clicker.module';

@NgModule({
  declarations: [ProductCardComponent],
  imports: [
    CommonModule,
    FollowModule,
    LazyLoadImageModule,
    AddBtnModule,
    ClickerModule,
  ],
  exports: [ProductCardComponent],
})
export class ProductCardModule {}
