import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import Fuse from 'fuse.js';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  readonly CITIES_CACHE = 'CITIES_CACHE';
  readonly CURRENT_CITY = 'CURRENT_CITY';
  readonly FLAT_CITIES_CACHE = 'FLAT_CITIES_CACHE';

  private currentCity = {
    ID: 1,
    cityName: 'Минск',
    isPopular: true,
  };
  private cities = [];
  private fuse: Fuse<any>;
  private citySubject = new BehaviorSubject(this.currentCity.ID);

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private PLATFORM_ID,
  ) {
    if (isPlatformBrowser(this.PLATFORM_ID)) {
      this.cities = JSON.parse(localStorage.getItem(this.CITIES_CACHE));

      this.currentCity =
        JSON.parse(localStorage.getItem(this.CURRENT_CITY)) || this.currentCity;

      if (!this.cities) {
        this.http
          .get<any>('/api/v2/cities')
          .pipe(
            map((json) => json.result),
            tap((cities) =>
              localStorage.setItem(this.CITIES_CACHE, JSON.stringify(cities)),
            ),
          )
          .subscribe((cities) => {
            this.cities = cities;
            this.setForSearch();
          });
      } else {
        this.setForSearch();
      }
    }
  }

  public setForSearch() {
    let flatCities = JSON.parse(localStorage.getItem(this.FLAT_CITIES_CACHE));

    if (!flatCities) {
      flatCities = this.cities.map((item) => ({
        cityName: item.cityName,
        ID: item.ID,
        isPopular: item.isPopular,
      }));
      this.cities.forEach((item, i, array) => {
        flatCities = flatCities.concat(item.children);
      });
      localStorage.setItem(this.FLAT_CITIES_CACHE, JSON.stringify(flatCities));
    }
    this.fuse = new Fuse(flatCities, {
      keys: ['cityName'],
      threshold: 0.2,
    });
  }

  public getId() {
    return this.currentCity.ID;
  }

  public changeCity(currentCity: any) {
    this.currentCity = currentCity;
    localStorage.setItem(this.CURRENT_CITY, JSON.stringify(this.currentCity));
  }

  public getCurrentCity(): any {
    return this.currentCity;
  }

  public getCities(): any[] {
    return this.cities;
  }

  public searchCity(query: string) {
    if (query === '') {
      return this.cities;
    }

    return this.fuse
      .search(query)
      .map((searchItem) => searchItem.item)
      .slice(0, 10);
  }

  public get onChange$(): Observable<number> {
    return this.citySubject.asObservable();
  }
}
