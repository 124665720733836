import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerCarouselComponent } from './banner-carousel.component';
import { BannerCardComponent } from './banner-card/banner-card.component';
import { CarouselModule } from '../carousel/carousel.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [BannerCarouselComponent, BannerCardComponent],
  imports: [CommonModule, CarouselModule, LazyLoadImageModule],
  exports: [BannerCarouselComponent],
})
export class BannerCarouselModule {}
