<div class="product" (click)="openModal()">
  <div
    class="product__store-image"
    [lazyLoad]="product.shopLogoURL"
    [offset]="100"
  ></div>
  <img
    class="product__image"
    src="/assets/images/preload.png"
    [lazyLoad]="product.imageURL"
    [offset]="100"
    [alt]="product.name"
  />
  <p class="product__title">{{ product.name }}</p>
  <p class="product__price">{{ product.offerPrice }}р</p>
</div>
