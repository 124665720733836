import { Component, Input, OnInit } from '@angular/core';
import { ProductModalService } from '../../shared/services/product-modal.service';

@Component({
  selector: 'related-product-card',
  templateUrl: './related-product-card.component.html',
  styleUrls: ['./related-product-card.component.scss'],
})
export class RelatedProductCardComponent implements OnInit {
  // TODO: Change any to model type
  @Input() public product: any;

  constructor(private productModal: ProductModalService) {}

  ngOnInit(): void {}

  public openModal(): void {
    this.productModal.open(this.product.shopRelationID, this.product.shopID);
  }
}
