<div class="product-card" (click)="openModal()">
  <!-- TODO: Add logic to the follow button -->
  <follow [obj]="product" class="product-card__follow"></follow>

  <clicker
    [shop]="validStore"
    [product]="validProduct"
    (clickerEvent)="isClicker = !isClicker"
    class="product-card__clicker"
  ></clicker>

  <img
    [offset]="100"
    src="/assets/images/preload.png"
    [lazyLoad]="product.imageURL"
    [alt]="product.name"
    class="product-card__image"
  />

  <p class="product-card__amount">{{ product.volume }}</p>
  <div class="product-card__prices">
    <div class="product-card__current-price">
      <span class="hl hl--yellow hl--rotated hl--big">
        <span class="hl__text"
          >{{ product.offerPrice || product.regularPrice }}р</span
        >
      </span>
    </div>
    <div
      *ngIf="product.regularPrice != 0 && product.offerPrice"
      class="product-card__previous-price"
    >
      <span>{{ product.regularPrice }}р</span>
    </div>
  </div>
  <h2 class="product-card__title">{{ product.name }}</h2>
</div>
