import { Component, OnInit } from '@angular/core';
import { CategoryModel } from '../../shared/models/category.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  // TODO: Change any to banner model
  public banners: any[];
  public categories: CategoryModel[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      const { categories, banners } = this.route.snapshot.data.data;
      this.categories = categories;
      this.banners = banners;
    });
  }
}
