import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparisonCardComponent } from './comparison-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [ComparisonCardComponent],
  imports: [CommonModule, LazyLoadImageModule],
  exports: [ComparisonCardComponent],
})
export class ComparisonCardModule {}
