import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesComponent } from './categories.component';
import { BannerCarouselModule } from '../../domain/banner-carousel/banner-carousel.module';
import { CategoryCardModule } from '../../domain/category-card/category-card.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CategoriesComponent],
  imports: [
    CommonModule,
    BannerCarouselModule,
    CategoryCardModule,
    RouterModule,
  ],
  exports: [CategoriesComponent],
})
export class CategoriesModule {}
