import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CartService } from '../../shared/services/cart.service';
import { SessionService } from '../../shared/services/session.service';
import { PopupService } from '../../shared/services/popup.service';

@Component({
  selector: 'add-btn',
  templateUrl: './add-btn.component.html',
  styleUrls: ['./add-btn.component.scss'],
})
export class AddBtnComponent implements OnInit {
  @Input() product;
  @Input() store;
  @Output() addEvent = new EventEmitter<any>();

  constructor(
    private cartService: CartService,
    private sessionService: SessionService,
    private popupService: PopupService,
  ) {}

  ngOnInit(): void {}

  public addToCart(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.sessionService.isAuthenticated()) {
      this.popupService.show();
    } else {
      this.cartService.add(this.store, this.product).subscribe();
      this.addEvent.emit();
    }
  }
}
