<div class="container">
  <banner-carousel [banners]="banners"></banner-carousel>

  <div class="breadcrumb">
    <a href="/home" routerLink="/home">Главная</a> /
    <a href="/categories" routerLink="/categories">Категории</a>
  </div>

  <h2 class="subtitle">Категории</h2>
  <div class="categories">
    <div *ngFor="let category of categories" class="categories__card">
      <category-card [category]="category"></category-card>
    </div>
  </div>
</div>
