import { Component, Input, OnInit } from '@angular/core';
import { ProductModalService } from '../../shared/services/product-modal.service';

@Component({
  selector: 'comparison-card',
  templateUrl: './comparison-card.component.html',
  styleUrls: ['./comparison-card.component.scss'],
})
export class ComparisonCardComponent implements OnInit {
  // TODO: Change any to model type
  @Input() public firstProduct: any | null;
  @Input() public secondProduct: any | null;
  @Input() public firstShopId: any | null;
  @Input() public secondShopId: any | null;
  public product: any;

  constructor(private productModal: ProductModalService) {}

  ngOnInit(): void {
    this.product = this.firstProduct || this.secondProduct;
  }

  public openModal(
    shopRelationID: number | undefined,
    shopId: number | undefined,
  ): void {
    if (shopRelationID) {
      this.productModal.open(shopRelationID, shopId);
    }
  }
}
