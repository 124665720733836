import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { RouterModule } from '@angular/router';
import { WatchedStoresComponent } from './watched-stores/watched-stores.component';
import { WatchedProductsComponent } from './watched-products/watched-products.component';
import { ProfileAllComponent } from './all/profile-all.component';
import { StoreCardModule } from '../../domain/store-card/store-card.module';
import { ProductCardModule } from '../../domain/product-card/product-card.module';
import { TabGroupModule } from '../../domain/tab-group/tab-group.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { OffersCardModule } from '../../domain/offers-card/offers-card.module';

@NgModule({
  declarations: [
    ProfileComponent,
    WatchedStoresComponent,
    WatchedProductsComponent,
    ProfileAllComponent,
  ],
  imports: [
    CommonModule,
    TabGroupModule,
    RouterModule,
    StoreCardModule,
    ProductCardModule,
    NgxMasonryModule,
    OffersCardModule,
  ],
  exports: [
    ProfileComponent,
    WatchedStoresComponent,
    WatchedProductsComponent,
    ProfileAllComponent,
  ],
})
export class ProfileModule {}
