import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { BannerCarouselModule } from '../../domain/banner-carousel/banner-carousel.module';
import { CategoryCardModule } from '../../domain/category-card/category-card.module';
import { StoreCardModule } from '../../domain/store-card/store-card.module';
import { CarouselModule } from '../../domain/carousel/carousel.module';
import { RouterModule } from '@angular/router';
import { NavbarModule } from '../../domain/navbar/navbar.module';
import { OffersCardModule } from '../../domain/offers-card/offers-card.module';
import { ComparisonCardModule } from '../../domain/comparison-card/comparison-card.module';
import { NgxMasonryModule } from 'ngx-masonry';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    RouterModule,
    BannerCarouselModule,
    CategoryCardModule,
    StoreCardModule,
    CarouselModule,
    ComparisonCardModule,
    NavbarModule,
    OffersCardModule,
    ComparisonCardModule,
    NgxMasonryModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
