<div class="container">
  <div class="breadcrumb">
    <a href="/{{ paths.HOME }}" routerLink="/{{ paths.HOME }}">{{
      pathsRus.HOME
    }}</a>
    /
    <a>{{ pathsRus.PROFILE }}</a>
  </div>

  <h1 class="title">Профиль</h1>

  <div class="profile-content">
    <router-outlet></router-outlet>
  </div>
</div>
