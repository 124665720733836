<div class="container">
  <section class="navbar">
    <a class="navbar__logo" href="/" routerLink="/">
      <img
        class="navbar__logo-img"
        src="assets/images/logo.png"
        alt="Supercheck"
      />
    </a>

    <location-dropdown class="navbar__location"></location-dropdown>

    <search class="navbar__search"></search>

    <cart class="navbar__cart"></cart>

    <profile class="navbar__profile"></profile>
  </section>
</div>

<section class="mobile-navbar">
  <div class="mobile-navbar__top top">
    <location-dropdown></location-dropdown>
    <search class="top__search-btn"></search>
  </div>

  <!-- TODO: Change div to a tag in the buttons, add router links -->
  <div class="mobile-navbar__bottom bottom">
    <a [routerLink]="[paths.HOME]" class="bottom__section-btn">
      <i class="bottom__section-btn-icon home-mobile-icon"></i>
      <span class="bottom__section-btn-label">Главная</span>
    </a>
    <a class="bottom__section-btn">
      <cart></cart>
    </a>
    <a
      [routerLink]="[paths.PROFILE, paths.PROFILE_WATCH_PRODUCTS]"
      class="bottom__section-btn"
    >
      <i class="bottom__section-btn-icon home-mobile-icon"></i>
      <span class="bottom__section-btn-label">Слежу</span>
    </a>
    <a [routerLink]="[paths.PROFILE]" class="bottom__section-btn">
      <i class="bottom__section-btn-icon profile-mobile-icon"></i>
      <span class="bottom__section-btn-label">Кабинет</span>
    </a>
  </div>
</section>
