import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { ProductsService } from '../../shared/services/products.service';
import { StoreService } from '../../shared/services/store.service';
import { forkJoin, Observable } from 'rxjs';
import { WatchListService } from '../../shared/services/watch-list.service';
import { CartService } from '../../shared/services/cart.service';

@Injectable({ providedIn: 'root' })
export class ProfileResolver implements Resolve<any> {
  constructor(
    private storeService: StoreService,
    private productsService: ProductsService,
    private watchListService: WatchListService,
    private cartService: CartService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return forkJoin({
      stores: this.watchListService.preloadStores(),
      products: this.watchListService.preloadProducts(),
      cart: this.cartService.preloadCart(),
    });
  }
}
