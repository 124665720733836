import { Component, HostListener, OnInit } from '@angular/core';
import { PopupService } from '../../shared/services/popup.service';

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  constructor(public popup: PopupService) {}

  ngOnInit(): void {}

  public hidePopup(): void {
    this.popup.hide();
  }
}
