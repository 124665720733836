import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SessionService } from './session.service';
import { PopupService } from './popup.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WatchListService {
  // TODO: REFACTOR this all service in bright future, but it works

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private popupService: PopupService,
  ) {}

  private products: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private stores: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private static distinctProduct(obj): boolean {
    return (
      obj.hasOwnProperty('realPrice') || obj.hasOwnProperty('regularPrice')
    );
  }

  private static getId(obj): string {
    return (obj.ID || obj.id).toString();
  }

  private notAuthenticated() {
    this.popupService.show();
    return of(false);
  }

  public add(obj): Observable<any> {
    return !this.session.isAuthenticated()
      ? this.notAuthenticated()
      : WatchListService.distinctProduct(obj)
      ? this.addProduct(WatchListService.getId(obj))
      : this.addStore(WatchListService.getId(obj));
  }

  public delete(obj): Observable<any> {
    return !this.session.isAuthenticated()
      ? this.notAuthenticated()
      : WatchListService.distinctProduct(obj)
      ? this.deleteProduct(WatchListService.getId(obj))
      : this.deleteStore(WatchListService.getId(obj));
  }

  public isWatched(obj): Observable<any> {
    return this.session.isAuthenticated()
      ? WatchListService.distinctProduct(obj)
        ? this.isProductWatched(WatchListService.getId(obj))
        : this.isStoreWatched(WatchListService.getId(obj))
      : of(false);
  }

  private isProductWatched(productId: string): Observable<any> {
    return this.isProductsCached()
      ? of(this.isProductCached(productId))
      : this.getProducts().pipe(map((data) => this.isProductCached(productId)));
  }

  private isStoreWatched(storeId: string): Observable<any> {
    return this.isStoresCached()
      ? of(this.isStoreCached(storeId))
      : this.getStores().pipe(map((data) => this.isStoreCached(storeId)));
  }

  public addStore(storeId: string): Observable<any> {
    return this.http.post<any>(`/api/v2/shops/${storeId}/watch`, {}).pipe(
      map((data) => {
        this.addStoreToCache(data.result);
      }),
    );
  }

  public deleteStore(storeId: string): Observable<any> {
    return this.http.delete(`/api/v2/shops/${storeId}/watch`, {}).pipe(
      map((data) => {
        this.delStoreFromCache(storeId);
      }),
    );
  }

  public getStores(): Observable<any> {
    if (this.isStoresCached()) {
      return this.stores.asObservable();
    }
    this.loadStores().subscribe();
    return this.stores.asObservable();
  }

  public getProducts(): Observable<any> {
    if (this.isProductsCached()) {
      return this.products.asObservable();
    }
    this.loadProducts().subscribe();
    return this.products.asObservable();
  }

  public addProduct(productId: string): Observable<any> {
    return this.http.post(`/api/v2/products/${productId}/watch`, {}).pipe(
      map((data: any) => {
        this.addProductToCache(data.result);
      }),
    );
  }

  public deleteProduct(productId: string): Observable<any> {
    return this.http.delete(`/api/v2/products/${productId}/watch`, {}).pipe(
      map((data) => {
        this.delProductFromCache(productId);
      }),
    );
  }

  // Methods for cache
  // TODO: Refactor to more universal methods
  private isStoresCached(): boolean {
    return this.stores.getValue() !== null;
  }

  private isProductsCached(): boolean {
    return this.products.getValue() !== null;
  }

  private isStoreCached(storeId: string): boolean {
    return (
      this.stores
        .getValue()
        .findIndex((store) => WatchListService.getId(store) === storeId) !== -1
    );
  }

  private isProductCached(productId: string): boolean {
    return (
      this.products
        .getValue()
        .findIndex(
          (product) => WatchListService.getId(product) === productId,
        ) !== -1
    );
  }

  private delStoreFromCache(storeId: string): void {
    if (this.isStoresCached()) {
      const index = this.stores
        .getValue()
        .findIndex((store) => WatchListService.getId(store) === storeId);
      this.stores.getValue().splice(index, 1);
    }
  }

  private delProductFromCache(productId: string): void {
    if (this.isProductsCached()) {
      const index = this.products
        .getValue()
        .findIndex((product) => WatchListService.getId(product) === productId);
      this.products.getValue().splice(index, 1);
    }
  }

  private addStoreToCache(store): void {
    if (
      this.isStoresCached() &&
      !this.isStoreCached(WatchListService.getId(store))
    ) {
      this.stores.getValue().push(store);
    }
  }

  private addProductToCache(product): void {
    if (
      this.isProductsCached() &&
      !this.isProductCached(WatchListService.getId(product))
    ) {
      this.products.getValue().push(product);
    }
  }

  private loadStores(): Observable<any> {
    return this.http.get('/api/v2/watched-shops').pipe(
      map((json: any) => {
        this.stores.next(json.result);
        return json.result;
      }),
    );
  }

  private loadProducts(): Observable<any> {
    return this.http.get('/api/v2/watched-products').pipe(
      map((json: any) => {
        this.products.next(json.result);
        return json.result;
      }),
    );
  }

  public preloadStores(): Observable<any> {
    if (this.session.isAuthenticated() && !this.isStoresCached()) {
      return this.loadStores();
    }
    return of(this.stores.getValue());
  }

  public preloadProducts(): Observable<any> {
    if (this.session.isAuthenticated() && !this.isProductsCached()) {
      return this.loadProducts();
    }
    return of(this.products.getValue());
  }
}
