<section class="carousel">
  <div #container (scroll)="onScrollHandler()" class="carousel__container">
    <ng-content></ng-content>
  </div>

  <!-- TODO: Add progress indicators-->

  <button
    [class.btn--invisible]="isFirstSlide()"
    (click)="scrollHorizontallyBy(-unitsToScrollBy)"
    class="carousel__button btn btn--left"
  >
    <i class="btn__arrow-icon"></i>
  </button>
  <button
    [class.btn--invisible]="isLastSlide()"
    (click)="scrollHorizontallyBy(unitsToScrollBy)"
    class="carousel__button btn btn--right"
  >
    <i class="btn__arrow-icon"></i>
  </button>
</section>
