import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShopModel } from '../models/shop.model';
import { map } from 'rxjs/operators';
import { CityService } from './city.service';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(private http: HttpClient, private cityService: CityService) {}

  public getStoreInfo(storeId: number): Observable<ShopModel> {
    return this.http
      .get<any>(`/api/v2/shops/${storeId}`)
      .pipe(map((json) => json.result));
  }

  public getStores(getPart: boolean = false): Observable<ShopModel[]> {
    return this.http
      .get(`/api/v2/city/${this.cityService.getId()}/shops`, {
        params: {
          getPart: getPart.toString(),
        },
      })
      .pipe(map((json: any) => json.result));
  }

  // TODO: Change any to model type
  public getComparedStores(): Observable<any> {
    return this.http
      .get<any>('/api/v2/compared-shops', {
        params: {
          cityId: this.cityService.getId().toString(),
        },
      })
      .pipe(
        map(([firstShop, secondShop]) => {
          if (!firstShop || !secondShop) {
            return null;
          }

          if (firstShop.products.length < secondShop.products.length) {
            [firstShop, secondShop] = [secondShop, firstShop];
          }

          return {
            validUntil: firstShop.flierActiveTill || secondShop.flierActiveTill,
            firstName: firstShop.name,
            secondName: secondShop.name,
            firstStoreId: firstShop.id,
            secondStoreId: secondShop.id,
            products: firstShop.products.map((firstProduct) => [
              firstProduct,
              secondShop.products.find(
                (secondProduct) => firstProduct.id === secondProduct.id,
              ),
            ]),
          };
        }),
      );
  }

  public getStoreById(storeId: number): Observable<any> {
    return this.http
      .get(`/api/v2/shops/${storeId}`)
      .pipe(map((json: any) => json.result));
  }

  public getStoresFlyer(storeId: number): Observable<any> {
    return this.http
      .get(`/api/v2/shop/${storeId}/flier`)
      .pipe(map((json: any) => json.result?.fliers?.flat(Infinity) || []));
  }

  public getUpdatedDate(): Observable<any> {
    return this.http
      .get('/api/v2/shop-comparisons', {
        params: {
          cityId: this.cityService.getId().toString(),
        },
      })
      .pipe(
        map((json: any) => (json[0]?.description ? json[0].description : null)),
      );
  }
}
