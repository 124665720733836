import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { KeyCodes } from '../../../../shared/enums/key-codes.enum';

@Component({
  selector: 'suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SuggestionsComponent implements OnInit {
  @Input() suggestions: string[];
  @Output() selectEvent = new EventEmitter<number>();
  private readonly keyCodes = KeyCodes;
  public selectedItem = -1;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.selectedItem = this.selectItem(event);
    this.selectEvent.emit(this.selectedItem);
  }

  constructor() {}

  ngOnInit(): void {}

  private selectItem(event: KeyboardEvent): number {
    if (event.keyCode === this.keyCodes.DOWN_ARROW) {
      if (this.suggestions.length === 1) {
        return -1;
      }
      if (this.selectedItem < this.suggestions.length - 1) {
        return ++this.selectedItem;
      } else {
        return -1;
      }
    }
    if (event.keyCode === this.keyCodes.UP_ARROW) {
      event.preventDefault();
      if (this.suggestions.length === 1) {
        return -1;
      }
      if (this.selectedItem === -1) {
        return this.suggestions.length - 1;
      } else {
        return --this.selectedItem;
      }
    }
    return -1;
  }

  public closeSuggestions(): void {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
}
