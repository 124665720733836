import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  readonly USER_KEY = 'USER_KEY';

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId,
  ) {}

  public sendPhoneNumber(phoneNumber: string) {
    return this.http
      .post<any>('/api/v2/sessions', {
        phone: phoneNumber.slice(1),
      })
      .pipe(
        tap((json) => {
          localStorage.setItem(this.USER_KEY, JSON.stringify(json));
        }),
      );
  }

  public getUser() {
    if (isPlatformServer(this.platformId)) {
      return null;
    }

    return JSON.parse(localStorage.getItem(this.USER_KEY));
  }

  public isAuthenticated() {
    return !!this.getUser()?.userId;
  }

  public logout() {
    localStorage.setItem(this.USER_KEY, null);
  }

  public refreshToken() {
    return this.http.post<any>('/api/v2/sessions/me/refresh', {}).pipe(
      tap((user) => {
        localStorage.setItem(this.USER_KEY, JSON.stringify(user));
      }),
    );
  }

  public sendConfirmationCode(code: string): Observable<any> {
    return this.http
      .post<any>('api/v2/sessions/me/confirm', {
        confirmationCode: code,
      })
      .pipe(
        tap((json) => {
          localStorage.setItem(this.USER_KEY, JSON.stringify(json));
        }),
      );
  }
}
