import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductModalComponent } from './product-modal.component';
import { FollowModule } from '../follow/follow.module';
import { CarouselModule } from '../carousel/carousel.module';
import { RelatedProductCardModule } from '../related-product-card/related-product-card.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';

@NgModule({
  declarations: [ProductModalComponent],
  imports: [
    CommonModule,
    FollowModule,
    CarouselModule,
    RelatedProductCardModule,
    LazyLoadImageModule,
    AngularYandexMapsModule,
  ],
  exports: [ProductModalComponent],
})
export class ProductModalModule {}
