import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { SearchService } from '../../../shared/services/search.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, AfterViewInit {
  @ViewChild('inputElement', { static: false }) inputElementRef: ElementRef;
  private url: string;
  private id: string;
  private query: string;
  public suggestions: string[] = [];

  constructor(private searchService: SearchService, private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        this.url = event.url;
        if (this.url.includes('search')) {
          this.query = this.getQueryFromUrl(event.url);
          this.inputElementRef.nativeElement.value = this.query;
        }
        this.id = this.getIdFromUrl(event.url);
      });
  }

  ngAfterViewInit() {
    fromEvent(this.inputElementRef.nativeElement, 'input')
      .pipe(
        map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
        debounceTime(300),
        filter((query) => query.length > 2),
        filter((query) => query !== ''),
        map((query) => query.trim()),
        distinctUntilChanged(),
        switchMap((query: string) => {
          return this.searchService.searchPopular();
        }),
      )
      .subscribe((result) => {
        this.suggestions = result;
      });
  }

  // TODO: think about better solution
  private getIdFromUrl(url: string): string {
    const pageFirst = 1;
    let pageLast: number = pageFirst;

    while (url[pageLast] !== '/' && pageLast <= url.length - 1) {
      pageLast += 1;
    }

    const pageName = url.substring(pageFirst, pageLast);

    const idFirst = url.indexOf(pageName) + pageName.length + 1;
    let idLast: number = idFirst;

    while (url[idLast] !== '/' && idLast <= url.length - 1) {
      idLast += 1;
    }

    return url.substring(idFirst, idLast);
  }

  public setSuggestion($event): void {
    if ($event !== -1) {
      if (typeof $event === 'number') {
        this.inputElementRef.nativeElement.value = this.suggestions[$event];
      } else {
        this.inputElementRef.nativeElement.value = $event;
      }
    }
  }

  public async searchPage() {
    this.inputElementRef.nativeElement.blur();
    await this.router.navigate([
      '/search',
      this.inputElementRef.nativeElement.value,
    ]);
  }

  public openSearchForm() {
    this.inputElementRef.nativeElement.focus();
  }

  public async search() {}

  private getQueryFromUrl(url: string): string {
    const queryStart = url.lastIndexOf('/') + 1;
    const queryEnd = url.length;
    return decodeURIComponent(url.substring(queryStart, queryEnd));
  }
}
