<div class="container">
  <div class="breadcrumb">
    <a href="/" routerLink="/">Главная</a> /
    <a href="/store/{{ store.id }}" routerLink="/store/{{ store.id }}">{{
      store.name
    }}</a>
    /
    <a
      href="/store/{{ store.id }}/flyer"
      routerLink="/store/{{ store.id }}/flyer"
      >Листовка</a
    >
  </div>

  <h1 class="title">Листовка {{ store.name }}</h1>

  <div
    class="images-container"
    infinite-scroll
    [infiniteScrollDistance]="3"
    [fromRoot]="true"
    (scrolled)="scrollDown()"
  >
    <div *ngIf="flyers.length === 0">Нет картинок</div>
    <div *ngFor="let image of flyers.slice(0, max)" class="products__card">
      <img class="flyer-img" [src]="image" alt="store flyer" />
    </div>
  </div>
</div>
