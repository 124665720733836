import { Component, OnInit } from '@angular/core';
import { ShopModel } from '../../shared/models/shop.model';
import { CategoryModel } from '../../shared/models/category.model';
import { ActivatedRoute } from '@angular/router';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public stores: ShopModel[];
  public categories: CategoryModel[];
  // TODO: Change any to banner model
  public banners: any[];
  public popular: any[];
  public comparedStores: any;
  public comparisonUpdated;

  public masonryOptions: NgxMasonryOptions = {
    gutter: 16,
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      const {
        stores,
        categories,
        banners,
        popular,
        comparedStores,
        updatedDate,
      } = data.data;

      this.stores = stores;
      this.categories = categories;
      this.banners = banners;
      this.popular = popular;
      this.comparedStores = comparedStores;
      this.comparisonUpdated = updatedDate;
    });
  }

  public times(n: number) {
    return new Array(Math.ceil(n)).map((el, i) => i);
  }
}
