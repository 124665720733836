import { Component, Input, OnInit } from '@angular/core';
import { WatchListService } from '../../shared/services/watch-list.service';
import { SessionService } from '../../shared/services/session.service';
import { PopupService } from '../../shared/services/popup.service';

@Component({
  selector: 'follow',
  templateUrl: './follow.component.html',
  styleUrls: ['./follow.component.scss'],
})
export class FollowComponent implements OnInit {
  // TODO: Add ng model on follow event
  @Input() obj: object;
  public activated = false;

  constructor(
    private watchListService: WatchListService,
    private sessionService: SessionService,
    private popupService: PopupService,
  ) {}

  ngOnInit(): void {
    this.watchListService
      .isWatched(this.obj)
      .subscribe((value) => (this.activated = value));
  }

  public toggleButton(event) {
    this.sessionService.isAuthenticated()
      ? this.activated
        ? this.watchListService
            .delete(this.obj)
            .subscribe(() => (this.activated = false))
        : this.watchListService
            .add(this.obj)
            .subscribe(() => (this.activated = true))
      : this.popupService.show();
    event.stopPropagation();
  }
}
