import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CartService } from '../../shared/services/cart.service';
import { SessionService } from '../../shared/services/session.service';
import { PopupService } from '../../shared/services/popup.service';

@Component({
  selector: 'clicker',
  templateUrl: './clicker.component.html',
  styleUrls: ['./clicker.component.scss'],
})
export class ClickerComponent implements OnInit {
  @Input() product;
  @Input() shop;
  @Output() clickerEvent = new EventEmitter<any>();
  public isClickerActive = false;

  constructor(
    private cartService: CartService,
    private session: SessionService,
    private popup: PopupService,
  ) {}

  ngOnInit(): void {
    if (this.session.isAuthenticated()) {
      this.cartService.getCart().subscribe((data) => {
        this.product.amount = this.setProductAmount(data.products);
        this.shop.products = this.setShopsProducts(data.products);
        this.isClickerActive = this.product.amount > 0;
      });
    }
  }

  public add(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.session.isAuthenticated()) {
      this.cartService.add(this.shop, this.product).subscribe();
    } else {
      this.popup.show();
    }
  }

  public delete(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.product.amount === 1) {
      this.isClickerActive = false;
    }
    this.cartService.delete(this.shop, this.product).subscribe();
  }

  private setProductAmount(shops): number {
    const amount = shops
      .find((shop) => shop.shopID === this.shop.shopID)
      ?.products.find(
        (product) =>
          product.productShopRelationID === this.product.productShopRelationID,
      )?.amount;

    return amount === undefined ? 0 : amount;
  }

  private setShopsProducts(shops): any {
    const products = shops.find((shop) => shop.shopID === this.shop.shopID)
      ?.products;

    return products === undefined ? [] : products;
  }
}
