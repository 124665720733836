<div class="loading-bar">
  <loading-bar></loading-bar>
</div>

<navbar></navbar>

<popup></popup>

<product-modal></product-modal>

<div
  class="main-content"
  [class.main-content--loading]="loader.isLoading$ | async"
>
  <router-outlet></router-outlet>
</div>

<div class="footer"></div>
