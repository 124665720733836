import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as urlJoin from 'url-join';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class ProxyInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('/api') && isPlatformServer(this.platformId)) {
      request = request.clone({
        url: urlJoin(environment.apiBaseUrl, request.url),
      });
    }

    return next.handle(request);
  }
}
