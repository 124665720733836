import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlyerComponent } from './flyer.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FlyerComponent],
  imports: [CommonModule, InfiniteScrollModule, RouterModule],
  exports: [FlyerComponent],
})
export class FlyerModule {}
