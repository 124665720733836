<section class="offers-card">
  <div class="offers-card__product">
    <h3 class="offers-card__product-title">{{ product.name }}</h3>
    <span class="offers-card__product-amount">{{ product.volume }}</span>
  </div>

  <div class="offers-card__img-and-offers">
    <img
      class="offers-card__product-img"
      [src]="getImageUrl()"
      (click)="showHint()"
    />

    <div class="offers-card__offers">
      <span *ngIf="product.shops.length === 0"
        >На данный товар пока нет акций</span
      >
      <div
        *ngFor="let shop of product.shops"
        class="offers-card__offer-card-wrapper"
      >
        <offer-card [shop]="shop" [product]="product"></offer-card>
      </div>
    </div>
  </div>

  <span #hint (animationend)="showHint()" class="offers-card__hint"
    >Выберите подходящую сеть</span
  >
</section>
