import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'offers-card',
  templateUrl: './offers-card.component.html',
  styleUrls: ['./offers-card.component.scss'],
})
export class OffersCardComponent implements OnInit {
  @Input() product;
  @ViewChild('hint', { static: false }) hintRef: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  public getImageUrl() {
    return (
      this.product.imageURL || `http://supercheck.by${this.product.imageUrl}`
    );
  }

  public getId() {
    return this.product.ID || this.product.id;
  }

  public showHint(): void {
    this.hintRef.nativeElement.classList.toggle('offers-card__hint--displayed');
  }
}
