import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'popular',
  templateUrl: './popular.component.html',
  styleUrls: ['./popular.component.scss'],
})
export class PopularComponent implements OnInit {
  // TODO: Change any to model type
  public popular: any[];
  public max = 10;
  public masonryOptions: NgxMasonryOptions = {
    gutter: 16,
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.popular = data.data.popular;
    });
  }

  public scrollDown(): void {
    this.max += 10;
  }
}
