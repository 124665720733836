import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { ProductsService } from '../../shared/services/products.service';
import { WatchListService } from '../../shared/services/watch-list.service';
import { CartService } from '../../shared/services/cart.service';

@Injectable({ providedIn: 'root' })
export class PopularResolver implements Resolve<any> {
  constructor(
    private products: ProductsService,
    private watchList: WatchListService,
    private cartService: CartService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return forkJoin({
      watchedProducts: this.watchList.preloadProducts(),
      popular: this.products.getPopularProducts(),
      cart: this.cartService.preloadCart(),
    });
  }
}
