<section
  class="container"
  infiniteScroll
  [infiniteScrollDistance]="3"
  (scrolled)="scrollDown()"
>
  <div class="header">
    <span *ngIf="products.length === 0; else foundedBlock"
      >Ничего не найдено</span
    >
    <ng-template #foundedBlock>
      <span class="header__title"
        >{{ getFoundedWord(products.length) }} {{ products.length }}
        {{
          getDeclOfNum(products.length, ['товар', 'товара', 'товаров'])
        }}</span
      >
    </ng-template>
    <app-select
      [options]="selectOptions"
      [selected]="defaultOption"
      (select)="selectOption($event)"
    >
      <ng-template let-option>
        <div>{{ option.name }}</div>
      </ng-template>
    </app-select>
  </div>

  <ngx-masonry [options]="{ gutter: 16 }" [ordered]="true">
    <div ngxMasonryItem class="offer-card" *ngFor="let product of products">
      <offers-card [product]="product"></offers-card>
    </div>
  </ngx-masonry>
</section>
