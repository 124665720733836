<section class="auth">
  <form
    (ngSubmit)="onFirstStepSubmit()"
    [formGroup]="firstStepFormGroup"
    *ngIf="activeStepIndex === 1"
  >
    <div class="auth__first-step">
      <h2 class="auth__subtitle subtitle">Вход или регистрация</h2>
      <div class="auth__input-field">
        <div
          class="auth__invalid-message"
          [style.opacity]="
            firstStepFormGroup.invalid && firstStepFormGroup.touched ? 1 : 0
          "
        >
          <p>Неправильно</p>
        </div>
        <input
          type="tel"
          [class.auth__phone-number--invalid]="
            firstStepFormGroup.invalid && firstStepFormGroup.touched
          "
          appAutofocus
          class="auth__phone-number"
          formControlName="phoneNumber"
          mask="(00) 000 00 00"
          prefix="+375 "
        />
      </div>
      <button class="button button--big button--fluid">Получить код</button>
    </div>
  </form>

  <form [formGroup]="secondStepFormGroup" *ngIf="activeStepIndex === 2">
    <div class="auth__second-step">
      <h2 class="auth__subtitle subtitle">Введите код</h2>
      <p class="auth__subtitle-text">Мы отправили код на номер:</p>
      <p class="auth__number">{{ phoneNumber }}</p>
      <a class="auth__link" (click)="activeStepIndex = 1">Изменить</a>
      <div class="auth__input-field auth__code-number-field">
        <input
          type="text"
          formControlName="codeNumber"
          appAutofocus
          [class.auth__invalid]="codeNumberInvalid"
          class="auth__code-number"
          [maxlength]="4"
          (ngModelChange)="onCodeNumberInput($event)"
        />
      </div>
      <a class="auth__link" (click)="getNewCode()">Получить новый код</a>
      <a
        class="auth__link auth__sms"
        (click)="smsDropdownVisible = !smsDropdownVisible"
        >Не приходит СМС?</a
      >
      <div class="auth__sms-dropdown" *ngIf="smsDropdownVisible">
        Попробуйте еще раз через пару минут
      </div>
    </div>
  </form>
</section>
