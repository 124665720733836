import { Component, OnInit } from '@angular/core';
import { ShopModel } from '../../shared/models/shop.model';
import { ActivatedRoute } from '@angular/router';
import { WatchListService } from '../../shared/services/watch-list.service';

@Component({
  selector: 'stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
})
export class StoresComponent implements OnInit {
  // TODO: Change any to banner model
  public banners: any[];
  public stores: ShopModel[];

  constructor(
    private route: ActivatedRoute,
    private watchList: WatchListService,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      const { stores, banners } = data.data;
      this.stores = stores;
      this.banners = banners;
    });
  }
}
