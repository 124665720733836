import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBtnComponent } from './add-btn.component';

@NgModule({
  declarations: [AddBtnComponent],
  imports: [CommonModule],
  exports: [AddBtnComponent],
})
export class AddBtnModule {}
