import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { routes } from './app-routing';
import { ProxyInterceptor } from './shared/interceptors/proxy.interceptor';
import { HomeModule } from './pages/home/home.module';
import { AuthModule } from './pages/auth/auth.module';
import { CategoriesModule } from './pages/categories/categories.module';
import { CategoryModule } from './pages/category/category.module';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { ProfileModule } from './pages/profile/profile.module';
import { StoreModule } from './pages/store/store.module';
import { StoresModule } from './pages/stores/stores.module';
import { environment } from '../environments/environment';
import { NavbarModule } from './domain/navbar/navbar.module';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { RefreshTokenInterceptor } from './shared/interceptors/refresh-token.interceptor';
import { ComparisonModule } from './pages/comparison/comparison.module';
import { FlyerModule } from './pages/flyer/flyer.module';
import { LoadingBarModule } from './domain/loading-bar/loading-bar.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopularModule } from './pages/popular/popular.module';
import { SearchModule } from './pages/search/search.module';
import { ProductModalModule } from './domain/product-modal/product-modal.module';
import { AngularYandexMapsModule, YA_CONFIG } from 'angular8-yandex-maps';
import { PopupModule } from './domain/popup/popup.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { CacheInterceptor } from './shared/interceptors/cache.interceptor';

// TODO: If TransferHttpCacheModule enabled, cart works bad
@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule,
    CategoriesModule,
    CategoryModule,
    HomeModule,
    NotFoundModule,
    ProfileModule,
    StoreModule,
    StoresModule,
    ComparisonModule,
    FlyerModule,
    PopularModule,
    SearchModule,

    HttpClientModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    AngularYandexMapsModule,
    SharedModule,
    PopupModule,
    NavbarModule,
    LoadingBarModule,
    ProductModalModule,

    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production,
      onSameUrlNavigation: 'reload',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProxyInterceptor,
      multi: true,
    },
    {
      provide: YA_CONFIG,
      useValue: {
        apikey: 'dc9fa30a-33db-4459-994a-710ebbd2d467',
        lang: 'ru_RU',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
