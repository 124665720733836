import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../shared/services/products.service';
import { SortBy } from '../../shared/enums/sort-by.enum';
import { StoreService } from './store.service';

@Component({
  selector: 'store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit {
  // TODO: Change any to model type
  public products: any[];
  public store: any;
  public categories: any[];

  private page = 1;
  private shopId: number;

  public selectOptions = [
    { name: 'По популярности', sortBy: SortBy.POPULAR },
    { name: 'По скидке', sortBy: SortBy.DISCOUNT },
    { name: 'По цене', sortBy: SortBy.PRICE },
  ];
  public defaultOption = this.selectOptions[0];

  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private router: Router,
    private storeService: StoreService,
  ) {
    this.shopId = +this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      const { products, categories, store } = data.data;

      this.store = store;
      this.products = products;
      this.categories = categories;
    });
  }

  public async selectOption(option) {
    this.storeService.sortBy = option.sortBy;
    await this.router.navigate([location.pathname]);
  }

  public async onToggle(index: number) {
    this.storeService.categoryId = this.categories[index - 1]?.ID;
    await this.router.navigate([location.pathname]);
  }

  public scrollDown(): void {
    this.page += 1;
    this.productsService
      .getProducts(
        this.shopId,
        this.page,
        this.storeService.categoryId,
        this.storeService.sortBy,
      )
      .subscribe((products) => {
        this.products = this.products.concat(products || []);
      });
  }
}
