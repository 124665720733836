import { Component, Input, OnInit } from '@angular/core';
import { ProductModalService } from '../../../shared/services/product-modal.service';

@Component({
  selector: 'offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
})
export class OfferCardComponent implements OnInit {
  // TODO: Add models
  @Input() shop: any;
  @Input() product: any;
  public validProduct;
  public validStore;

  constructor(private productModal: ProductModalService) {}

  ngOnInit(): void {
    this.validProduct = {
      name: this.product.name,
      productImageURL: this.getProductImageUrl(),
      offerPrice: this.shop.offerPrice || this.shop.regularPrice,
      productShopRelationID: this.getShopRelationId(),
      amount: 0,
    };
    this.validStore = {
      shopID: this.getShopId(),
      shopName: this.shop.name,
      shopLogoURL: this.getShopImageUrl(),
      products: [],
    };
  }

  public openModal(shopRelationID: number): void {
    this.productModal.open(shopRelationID, this.getShopId());
  }

  public isImageExist() {
    return this.shop.imageURL || this.shop.imageUrl;
  }

  public getShopImageUrl() {
    const imageUrl = this.shop.imageURL || this.shop.imageUrl;
    if (imageUrl.startsWith('http://')) {
      return imageUrl;
    }
    return `http://supercheck.by${imageUrl}`;
  }

  public getShopId() {
    return this.shop.shopID || this.shop.ID || this.shop.id;
  }

  public getShopRelationId() {
    return this.shop.productShopRelationID || this.shop.productShopRelationId;
  }

  public getProductImageUrl() {
    return (
      this.product.imageURL || `http://supercheck.by${this.product.imageUrl}`
    );
  }

  public isValid(price) {
    return price !== 0 && price !== '0' && price !== null;
  }
}
