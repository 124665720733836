import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparisonComponent } from './comparison.component';
import { ComparisonCardModule } from '../../domain/comparison-card/comparison-card.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ComparisonComponent],
  imports: [ComparisonCardModule, CommonModule, RouterModule],
  exports: [ComparisonComponent],
})
export class ComparisonModule {}
