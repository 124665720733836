import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoresComponent } from './stores.component';
import { BannerCarouselModule } from '../../domain/banner-carousel/banner-carousel.module';
import { StoreCardModule } from '../../domain/store-card/store-card.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [StoresComponent],
  imports: [CommonModule, BannerCarouselModule, StoreCardModule, RouterModule],
  exports: [StoresComponent],
})
export class StoresModule {}
