import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductModalService } from '../../shared/services/product-modal.service';
import { CartService } from '../../shared/services/cart.service';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() public product;
  @Input() public store;
  @Output() hideClickerEvent = new EventEmitter<any>();
  public validProduct;
  public validStore;
  public isClicker = false;

  constructor(
    private productModal: ProductModalService,
    private cartService: CartService,
    private session: SessionService,
  ) {}

  ngOnInit(): void {
    this.validProduct = {
      name: this.product.name,
      productImageURL: this.product.imageURL,
      offerPrice: this.product.offerPrice || this.product.regularPrice,
      productShopRelationID: this.getShopRelationId(),
      amount: 0,
    };
    this.validStore = {
      shopID: this.getShopId(),
      shopName: this.store.name,
      shopLogoURL: this.store.imageUrl,
      products: [],
    };
  }

  public openModal(): void {
    this.productModal.open(this.product.productShopRelationID, this.store.id);
  }

  public getShopId() {
    return this.store.shopID || this.store.ID || this.store.id;
  }

  public getShopRelationId() {
    return (
      this.product.productShopRelationID || this.product.productShopRelationId
    );
  }
}
