<div class="tab-group">
  <tab-group
    (toggle)="onToggle($event)"
    [defaultToggledIndex]="0"
    [allVisible]="true"
  >
    <ng-template #option>Все</ng-template>
    <ng-template #option>Товары в слежу</ng-template>
    <ng-template #option>Магазины в слежу</ng-template>
  </tab-group>
</div>
<section class="profile-all">
  <span class="profile-all__phone">+{{ this.session.getUser()?.phone }}</span>
  <span class="profile-all__logout" (click)="logout()">Выйти</span>
</section>
