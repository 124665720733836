import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class WindowScrollingService {
  private styleTag: HTMLStyleElement;
  private enabled = true;

  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.styleTag = this.buildStyleElement();
    }
  }

  public disable(): void {
    if (this.enabled) {
      this.document.body.appendChild(this.styleTag);
      this.enabled = false;
    }
  }

  public enable(): void {
    if (!this.enabled) {
      this.document.body.removeChild(this.styleTag);
      this.enabled = true;
    }
  }

  private buildStyleElement(): HTMLStyleElement {
    const styleElement = this.document.createElement('style');

    styleElement.setAttribute(
      'data-debug',
      'Injected by WindowScrolling service.',
    );
    styleElement.textContent = `
			body {
				overflow: hidden !important;
			}
		`;

    return styleElement;
  }
}
