import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryComponent } from './category.component';
import { OffersCardModule } from '../../domain/offers-card/offers-card.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SelectModule } from '../../domain/select/select.module';
import { CategoryService } from './category.service';
import { TabGroupModule } from '../../domain/tab-group/tab-group.module';

@NgModule({
  declarations: [CategoryComponent],
  imports: [
    CommonModule,
    TabGroupModule,
    OffersCardModule,
    NgxMasonryModule,
    RouterModule,
    InfiniteScrollModule,
    SelectModule,
  ],
  exports: [CategoryComponent],
  providers: [CategoryService],
})
export class CategoryModule {}
