import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffersCardComponent } from './offers-card.component';
import { OfferCardComponent } from './offer-card/offer-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AddBtnModule } from '../add-btn/add-btn.module';
import { ClickerModule } from '../clicker/clicker.module';

@NgModule({
  declarations: [OffersCardComponent, OfferCardComponent],
  imports: [CommonModule, LazyLoadImageModule, AddBtnModule, ClickerModule],
  exports: [OffersCardComponent],
})
export class OffersCardModule {}
