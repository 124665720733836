<div class="tab-group">
  <tab-group
    (toggle)="onToggle($event)"
    [defaultToggledIndex]="2"
    [allVisible]="true"
  >
    <ng-template #option>Все</ng-template>
    <ng-template #option>Товары в слежу</ng-template>
    <ng-template #option>Магазины в слежу</ng-template>
  </tab-group>
</div>
<section class="stores">
  <div *ngFor="let store of stores" class="stores__card">
    <store-card [shop]="store"></store-card>
  </div>
</section>
