import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CityService } from './city.service';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { WindowScrollingService } from './window-scrolling.service';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { CartService } from './cart.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ProductModalService {
  // TODO: Change any to model type
  private stateSubject = new BehaviorSubject<boolean>(false);
  private productSubject = new BehaviorSubject<any | null>(null);
  private shopSubject = new BehaviorSubject<any | null>(null);
  private isAddedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private city: CityService,
    private windowScrolling: WindowScrollingService,
    private loader: LoaderService,
    private router: Router,
    private cartService: CartService,
    private sessionService: SessionService,
  ) {
    this.router.events.subscribe((event) => {
      this.close();
    });
  }

  public open(shopRelationID: number, shopId: number): void {
    this.isShopAdded(shopId)
      .pipe(
        switchMap((isShopAdded) =>
          forkJoin({
            product: this.changeProduct(shopRelationID),
            shop: this.changeShop(shopId, isShopAdded),
            isAdded: this.isAdded(shopRelationID),
          }),
        ),
      )
      .subscribe((data) => {
        this.productSubject.next(data.product);
        this.shopSubject.next(data.shop);
        this.isAddedSubject.next(data.isAdded);

        this.loader.finishLoading();
        this.stateSubject.next(true);
        this.windowScrolling.disable();
        window.scrollTo(0, 0);
      });
  }

  public close(): void {
    this.windowScrolling.enable();
    this.stateSubject.next(false);
  }

  public get isOpened$(): Observable<boolean> {
    return this.stateSubject.asObservable();
  }

  public get product$(): Observable<any | null> {
    return this.productSubject.asObservable();
  }

  public get shop$(): Observable<any | null> {
    return this.shopSubject.asObservable();
  }

  public get isAdded$(): Observable<boolean> {
    return this.isAddedSubject.asObservable();
  }

  private changeProduct(shopRelationID: number) {
    return this.http
      .get<any>(`/api/v2/product/city/${this.city.getId()}/${shopRelationID}`)
      .pipe(
        tap(() => this.loader.startLoading()),
        map((json) => {
          if (!json.result.offerPrice) {
            json.result.offerPrice = json.result.regularPrice;
          }
          json.result['productImageURL'] = json.result.imageURL;
          json.result['productShopRelationID'] = shopRelationID;
          return json.result;
        }),
      );
  }

  private changeShop(shopId: number, isShopAdded: boolean) {
    if (isShopAdded) {
      return this.cartService.preloadCart().pipe(
        map((data) => {
          return data.products.find((shop) => shop.shopID === shopId);
        }),
      );
    }

    return this.http.get<any>(`/api/v2/shops/${shopId}`).pipe(
      map((json) => {
        return {
          shopID: json.result.id,
          shopName: json.result.name,
          shopLogoURL: json.result.imageUrl,
          products: [],
        };
      }),
    );
  }

  private isAdded(shopRelationId: number) {
    if (this.sessionService.isAuthenticated()) {
      return this.cartService
        .isInCart(shopRelationId)
        .pipe(map((isIn) => isIn));
    }
    return of(false);
  }

  private isShopAdded(shopId: number) {
    if (this.sessionService.isAuthenticated()) {
      return this.cartService
        .preloadCart()
        .pipe(
          map((cart) => cart.products.some((shop) => shop.shopID === shopId)),
        );
    }
    return of(false);
  }
}
