<div class="store-card">
  <a (click)="openStore()" class="store-card__link-wrapper">
    <!-- TODO: Add logic for follow button -->
    <follow [obj]="shop" class="store-card__follow"></follow>

    <img
      class="store-card__image"
      src="assets/images/preload.png"
      [lazyLoad]="getImageUrl()"
      alt="store name"
    />

    <div class="store-card__info">
      <h2 class="store-card__name">{{ shop.name }}</h2>
      <p class="store-card__product-count">
        <ng-container *ngIf="shop.productsCount > 0; else elseBlock"
          >{{ shop.productsCount }} товаров</ng-container
        >
        <ng-template #elseBlock>Только листовка</ng-template>
      </p>

      <div class="store-card__separator separator">
        <div class="separator__circle" *ngFor="let i of range(30)"></div>
      </div>

      <div class="store-card__date">
        <p class="store-card__normal-date" *ngIf="hours <= 0">Нет информации</p>
        <div class="store-card__urgent-date" *ngIf="days <= 3 && hours > 0">
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99419 0.952148C2.77419 0.952148 0.166687 3.56548 0.166687 6.78548C0.166687 10.0055 2.77419 12.6188 5.99419 12.6188C9.22002 12.6188 11.8334 10.0055 11.8334 6.78548C11.8334 3.56548 9.22002 0.952148 5.99419 0.952148ZM6.00002 11.4521C3.42169 11.4521 1.33335 9.36382 1.33335 6.78548C1.33335 4.20715 3.42169 2.11882 6.00002 2.11882C8.57835 2.11882 10.6667 4.20715 10.6667 6.78548C10.6667 9.36382 8.57835 11.4521 6.00002 11.4521Z"
              fill="#FF5E37"
            />
            <path
              d="M6.29169 3.86914H5.41669V7.36914L8.47919 9.20664L8.91669 8.48914L6.29169 6.93164V3.86914Z"
              fill="#FF5E37"
            />
          </svg>
          {{ getUrgent() }}
        </div>
        <div class="store-card__normal-date" *ngIf="days > 3">
          Действует до: {{ dateStr }}
        </div>
      </div>
    </div>
  </a>
</div>
