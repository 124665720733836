import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @ContentChild(TemplateRef)
  public optionTemplate: TemplateRef<any>;

  @Input() public options: any[];
  @Input() public selected: any;
  @Input() public placeholder = 'Nothing selected';
  @Output() public select = new EventEmitter<any>();

  public itemsVisible = false;
  public bubbling = false;

  constructor() {}

  ngOnInit(): void {}

  public selectItem(item: any): void {
    if (this.selected !== item) {
      this.selected = item;
      this.select.emit(item);
    }
  }

  public toggleSelectDropdown(): void {
    this.toggleItems();
    this.bubbling = true;
  }

  public closeSelectDropdown(): void {
    if (this.bubbling) {
      this.bubbling = false;
    } else {
      this.hideItems();
    }
  }

  public toggleItems(): void {
    this.itemsVisible = !this.itemsVisible;
  }

  public hideItems(): void {
    this.itemsVisible = false;
  }
}
