import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements OnInit, AfterViewInit {
  @Input() public visibleCount = 7;
  @Input() public allVisible = false;
  @Input() public defaultToggledIndex = 0;

  @Output() public toggle = new EventEmitter<number>();

  public optionsVisible = this.allVisible;
  public notVisibleOptionsCount = 0;

  @ContentChildren('option')
  public options: QueryList<TemplateRef<any>>;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (!this.allVisible) {
      this.notVisibleOptionsCount = this.options.length - this.visibleCount;
    }

    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {}

  public hideOptions(): void {
    this.optionsVisible = false;
  }

  public showOptions(): void {
    this.optionsVisible = true;
  }

  public onToggle(index: number): void {
    this.defaultToggledIndex = index;
    this.toggle.emit(index);
  }
}
