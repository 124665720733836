import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationDropdownComponent } from './location-dropdown/location-dropdown.component';
import { CartComponent } from './cart/cart.component';
import { ProfileComponent } from './profile/profile.component';
import { NavbarComponent } from './navbar.component';
import { SearchComponent } from './search/search.component';
import { SuggestionsComponent } from './search/suggestions/suggestions.component';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../../shared/shared.module';
import { ClickerModule } from '../clicker/clicker.module';
import { ShopComponent } from './cart/shop/shop.component';

@NgModule({
  declarations: [
    LocationDropdownComponent,
    CartComponent,
    ProfileComponent,
    NavbarComponent,
    SearchComponent,
    SuggestionsComponent,
    ShopComponent,
  ],
  exports: [NavbarComponent, CartComponent],
  imports: [
    CommonModule,
    RouterModule,
    LazyLoadImageModule,
    SharedModule,
    ClickerModule,
  ],
})
export class NavbarModule {}
