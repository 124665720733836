import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit {
  @Input() shop;
  public isShown = true;

  constructor() {}

  ngOnInit(): void {}

  public toggleShop() {
    this.isShown = !this.isShown;
  }

  public getShopTotal(shop): number {
    let counter = 0;
    shop.products.forEach(
      (product) =>
        (counter +=
          (product.offerPrice || product.regularPrice) * product.amount),
    );
    return Number(counter.toFixed(2));
  }

  public getImageUrl(product) {
    if (product.productImageURL.includes('http')) {
      return product.productImageURL;
    }
    return `http://supercheck.by${product.productImageURL}`;
  }
}
