import { Injectable } from '@angular/core';
import { CityService } from './city.service';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SortBy } from '../enums/sort-by.enum';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient, private cityService: CityService) {}

  public searchInCity(
    query: string,
    page: number = 1,
    sortBy: SortBy = SortBy.POPULAR,
  ): Observable<any> {
    return this.http
      .get(`/api/v2/search/city/${this.cityService.getId()}/query/${query}`, {
        params: {
          pageNumber: page.toString(),
          sortBy: sortBy.toString(),
        },
      })
      .pipe(
        map((json: any) => json.result),
        catchError((error: Response) => {
          if (error.status === 404) {
            return of({ result: 'Not Found' });
          }
          return of({ result: 'New Error' });
        }),
      );
  }

  public searchInStore(
    query: string,
    shopId: string,
    page: number,
    sortBy: number,
  ): Observable<any> {
    return this.http
      .get(
        `/api/v2/search/shop/${shopId}/city/${this.cityService.getId()}/query/${query}`,
        {
          params: {
            pageNumber: page.toString(),
            sortBy: sortBy.toString(),
          },
        },
      )
      .pipe(
        map((json: any) => json.result),
        catchError((error: Response) => {
          if (error.status === 404) {
            return of({ result: 'Not Found' });
          }
          return of({ result: 'New Error' });
        }),
      );
  }

  public searchInCategory(
    query: string,
    categoryId: string,
    page: number,
    sortBy: number,
  ): Observable<any> {
    return this.http
      .get(
        `/api/v2/search/category/${categoryId}/city/${this.cityService.getId()}/query/${query}`,
        {
          params: {
            pageNumber: page.toString(),
            sortBy: sortBy.toString(),
          },
        },
      )
      .pipe(
        map((json: any) => json.result),
        catchError((error: Response) => {
          if (error.status === 404) {
            return of({ result: 'Not Found' });
          }
          return of({ result: 'New Error' });
        }),
      );
  }

  public searchSuggestions(query: string): Observable<any> {
    return this.http.get(`/api/v2/search/suggest/${query}`).pipe(
      map((json: any) =>
        json.result.length > 0 ? json.result : ['Ничего не найдено'],
      ),
      catchError((error: Response) => {
        if (error.status === 404) {
          return of(['Ничего не найдено']);
        }
        if (error.status === 400) {
          return of(['Минимум 3 символа']);
        }
        return of(['Ошибка']);
      }),
    );
  }

  public searchPopular() {
    return this.http.get('/api/v2/search-popular').pipe(
      map((json: any) =>
        json.result.length > 0 ? json.result : ['Ничего не найдено'],
      ),
      catchError((error: Response) => {
        if (error.status === 404) {
          return of(['Ничего не найдено']);
        }
        if (error.status === 400) {
          return of(['Минимум 3 символа']);
        }
        return of(['Ошибка']);
      }),
    );
  }
}
