<section class="location-dropdown">
  <div (click)="openDropdown()" class="location-dropdown__button">
    <span class="location-dropdown__title">{{
      cityService.getCurrentCity().cityName
    }}</span>

    <i class="location-dropdown__arrow-icon arrow-bottom-icon"></i>
  </div>

  <div (click)="openDropdown()" class="location-dropdown__mobile">
    <span class="location-dropdown__mobile-title">{{
      cityService.getCurrentCity().cityName
    }}</span>
    <i class="location-dropdown__mobile-btn arrow-bottom-icon"></i>
  </div>

  <section *ngIf="isOpened" class="location-dropdown__menu menu">
    <h3 class="menu__current-city">
      {{ cityService.getCurrentCity().cityName }}
    </h3>
    <ul class="menu__list">
      <ng-container *ngFor="let city of cityService.getCities()">
        <li
          *ngIf="city.ID !== cityService.getId()"
          (click)="changeCity(city)"
          class="menu__list-item"
        >
          {{ city.cityName }}
        </li>
      </ng-container>
      <li (click)="openForm()" class="menu__list-item menu__button">
        Выбрать другой
      </li>
    </ul>
  </section>

  <section *ngIf="isFormOpened" class="location-dropdown__form form">
    <div class="form__search-bar">
      <div class="form__search search">
        <i class="search__icon search-icon"></i>
        <input #input class="search__input" placeholder="Найти город" />
      </div>
      <button (click)="closeDropdown()" class="form__cancel">Отмена</button>
    </div>

    <ul class="form__search-results results">
      <li
        *ngFor="let city of cityService.searchCity(input.value)"
        (click)="changeCity(city)"
        class="results__item"
      >
        {{ city.cityName }}
      </li>
    </ul>
  </section>
</section>
<div
  (click)="closeDropdown()"
  *ngIf="isOpened || isFormOpened"
  class="background-shadow"
></div>
