import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopularComponent } from './popular.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { OffersCardModule } from '../../domain/offers-card/offers-card.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [PopularComponent],
  imports: [
    CommonModule,
    NgxMasonryModule,
    OffersCardModule,
    InfiniteScrollModule,
  ],
  exports: [PopularComponent],
})
export class PopularModule {}
