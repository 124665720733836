export enum PathsRus {
  HOME = 'Главная',
  STORES = 'Скидки сетей',
  STORE = 'Каталог',
  CATEGORIES = 'Категории',
  CATEGORY = 'Категория',
  PROFILE = 'Профиль',
  AUTH = 'Авторизация',
  STORE_COMPARISON = 'Сравнение магазинов',
  FLYER = 'Листовки',
  POPULAR = 'Популярные продукты',
}
