import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

@Component({
  selector: 'store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.scss'],
})
export class StoreCardComponent implements OnInit {
  @Input() public shop: any;

  private date;
  private now = Date.now();
  public dateStr = '';
  public hours = 0;
  public days = 0;

  constructor(private router: Router) {}

  ngOnInit(): void {
    dayjs.extend(customParseFormat);

    this.date = dayjs(
      this.shop.flierActiveTill,
      'YYYY-MM-DD hh:mm:ss',
    ).valueOf();
    this.dateStr = dayjs(this.date).format('d.MM');
    this.hours = Math.ceil((this.date - this.now) / (1000 * 60 * 60));
    this.days = Math.ceil(this.hours / 24);
  }

  public range(n: number) {
    return new Array(n);
  }

  public getImageUrl() {
    return this.shop.imageURL || `http://supercheck.by${this.shop.imageUrl}`;
  }

  public getId() {
    return this.shop.ID || this.shop.id;
  }

  public async openStore() {
    await this.router.navigate(['/store', this.getId()]);
  }

  public getUrgent() {
    const hours = this.hours;
    const days = this.days;

    if (hours <= 12) {
      return hours + ' ' + this.declOfNum(hours, ['час', 'часа', 'часов']);
    }

    return days + ' ' + this.declOfNum(days, ['день', 'дня', 'дней']);
  }

  private declOfNum(num: number, titles: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
    ];
  }
}
