import { Component, OnInit } from '@angular/core';
// import Timeout = NodeJS.Timeout;
import { LoaderService } from '../../shared/services/loader.service';

@Component({
  selector: 'loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit {
  readonly MAXIMUM = 100;
  readonly MINIMUM = 0;
  readonly INTERVAL = 100;
  readonly LOG_BASE = 20;

  public isLoading = true;
  public currentProgress = this.MINIMUM;
  private intervalDescriptor;

  constructor(private loader: LoaderService) {}

  ngOnInit(): void {
    this.loader.isLoading$.subscribe((isLoading) => {
      if (isLoading) {
        this.startLoading();
      } else {
        this.finishLoading();
      }
    });
  }

  public startLoading(): void {
    this.showLoadingBar();
    this.currentProgress = this.MINIMUM;
    this.updateProgress();
    this.intervalDescriptor = setInterval(
      this.updateProgress.bind(this),
      this.INTERVAL,
    );
  }

  public finishLoading(): void {
    this.currentProgress = this.MAXIMUM;
    clearInterval(this.intervalDescriptor);
    setTimeout(this.hideLoadingBar.bind(this), 1000);
  }

  private updateProgress(): void {
    const delta = (this.MAXIMUM - this.currentProgress) / this.LOG_BASE;
    this.currentProgress += delta;
  }

  private showLoadingBar(): void {
    this.isLoading = true;
  }

  private hideLoadingBar(): void {
    this.isLoading = false;
  }
}
