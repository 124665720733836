import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  // TODO: Refactor
  private cart: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private session: SessionService) {}

  private loadReq(): Observable<any> {
    return this.http.get('/api/v2/basket').pipe(
      map((json: any) => {
        this.cart.next(json.result);
        return json.result;
      }),
    );
  }

  private clearReq(): Observable<any> {
    return this.http.get('/api/v2/basket/clear');
  }

  private addReq(products): Observable<any> {
    return this.http.post('/api/v2/basket/add', products).pipe(
      map((json: any) => {
        return json.result;
      }),
    );
  }

  private deleteReq(productId): Observable<any> {
    return this.http.get(`/api/v2/basket/product/${productId}/delete`).pipe(
      map((json: any) => {
        return json.result;
      }),
    );
  }

  private setAmountReq(productId: string, amount: number): Observable<any> {
    return this.http
      .get(`/api/v2/basket/prdoduct/${productId}/amount/${amount}`)
      .pipe(
        map((json: any) => {
          console.log('json', amount, json);
          return json.result;
        }),
      );
  }

  public getCart(): Observable<any> {
    if (this.isCacheExist()) {
      return this.cart.asObservable();
    }
    this.loadReq().subscribe();
    return this.cart.asObservable();
  }

  private getCartLastValue(): Observable<any> {
    if (this.isCacheExist()) {
      return of(this.cart.getValue());
    }
    this.loadReq().subscribe();
    return of(this.cart.getValue());
  }

  public preloadCart(): Observable<any> {
    if (this.session.isAuthenticated() && !this.isCacheExist()) {
      return this.loadReq();
    }
    return of(this.cart.getValue());
  }

  private isCacheExist(): boolean {
    return this.cart.getValue() !== null;
  }

  public add(shop, product): Observable<any> {
    if (this.isCacheExist()) {
      this.addToCache(shop, product);
      this.setCachedTotal(this.countCacheTotal());
      return this.addToCart(shop.shopID, product.productShopRelationID);
    } else {
      return this.addToCart(shop.shopID, product.productShopRelationID);
    }
  }

  public delete(shop, product): Observable<any> {
    if (this.isCacheExist()) {
      this.deleteFromCache(shop, product);
      this.setCachedTotal(this.countCacheTotal());
      return this.deleteFromCart(shop.shopID, product.productShopRelationID);
    } else {
      return this.deleteFromCart(shop.shopID, product.productShopRelationID);
    }
  }

  public deleteProduct(shop, product): Observable<any> {
    if (this.isCacheExist()) {
      if (shop.products.length <= 1) {
        this.deleteShopAndProductFromCache(shop, product);
      } else {
        this.deleteProductFromCache(shop, product);
      }
      this.setCachedTotal(this.countCacheTotal());
      return this.deleteReq(product.productShopRelationID);
    } else {
      return this.deleteReq(product.productShopRelationID);
    }
  }

  public getAmount(shopId, productId): Observable<any> {
    if (this.isCacheExist()) {
      return of(this.getAmountInCache(shopId, productId));
    } else {
      return this.getAmountInCart(shopId, productId);
    }
  }

  private addToCart(shopId, productId): Observable<any> {
    return this.isInCart(productId).pipe(
      switchMap((isIn) => {
        if (isIn) {
          return this.getAmountInCart(shopId, productId).pipe(
            switchMap((amount) => {
              return this.setAmountReq(productId, amount);
            }),
          );
        } else {
          return this.addReq({ [productId]: 1 });
        }
      }),
    );
  }

  private addToCache(shop, product) {
    const isShopInCache = this.isShopInCache(shop.shopID);

    // Useless ?
    if (!product.hasOwnProperty('amount')) {
      product['amount'] = 1;
    }

    if (
      isShopInCache &&
      this.isProductInCache(shop.shopID, product.productShopRelationID)
    ) {
      this.setAmountInCache(shop, product, 1);
    } else if (isShopInCache) {
      this.addProductToCache(shop, product);
    } else {
      this.addShopAndProductToCache(shop, product);
    }
  }

  private deleteFromCart(shopId, productId): Observable<any> {
    return this.getAmountInCart(shopId, productId).pipe(
      switchMap((amount) => {
        if (amount > 0) {
          return this.setAmountReq(productId, amount);
        } else {
          return this.deleteReq(productId);
        }
      }),
    );
  }

  private deleteFromCache(shop, product) {
    const amount = this.getAmountInCache(
      shop.shopID,
      product.productShopRelationID,
    );

    if (amount > 1) {
      this.setAmountInCache(shop, product, -1);
    } else {
      if (shop.products.length <= 1) {
        this.deleteShopAndProductFromCache(shop, product);
      } else {
        this.deleteProductFromCache(shop, product);
      }
    }
  }

  public isInCart(productId): Observable<any> {
    return this.getCartLastValue().pipe(
      // loadReq()
      map((data) => {
        return data.products.some((shop) =>
          shop.products.some(
            (product) => product.productShopRelationID === productId,
          ),
        );
      }),
    );
  }

  public isInCache(shopId, productId): boolean {
    if (this.isShopInCache(shopId)) {
      return this.isProductInCache(shopId, productId);
    }
    return false;
  }

  private getAmountInCart(shopId, productId): Observable<any> {
    return this.getCartLastValue().pipe(
      // loadReq()
      map((data) => {
        return data.products
          .find((shop) => shop.shopID === shopId)
          ?.products.find((prod) => prod.productShopRelationID === productId)
          ?.amount;
      }),
    );
  }

  public getAmountInCache(shopId, productId): number {
    const tempCart = this.cart.getValue();

    return tempCart.products
      .find((store) => store.shopID === shopId)
      .products.find((prod) => prod.productShopRelationID === productId).amount;
  }

  private isShopInCache(shopId: number): boolean {
    return this.cart.getValue().products.some((shop) => shop.shopID === shopId);
  }

  private isProductInCache(shopId, productId): boolean {
    return this.cart
      .getValue()
      .products.find((shop) => shop.shopID === shopId)
      .products.some((product) => product.productShopRelationID === productId);
  }

  private setAmountInCache(shop, product, amount) {
    const tempCart = this.cart.getValue();

    const productsShop = tempCart.products.find(
      (store) => store.shopID === shop.shopID,
    );

    productsShop.products.find(
      (prod) => prod.productShopRelationID === product.productShopRelationID,
    ).amount += amount;

    this.cart.next(tempCart);
  }

  private addProductToCache(shop, product) {
    const tempCart = this.cart.getValue();
    product.amount = 1;

    const productsShop = tempCart.products.find(
      (store) => store.shopID === shop.shopID,
    );

    productsShop.products.push(product);

    this.cart.next(tempCart);
  }

  private addShopAndProductToCache(shop, product) {
    const tempCart = this.cart.getValue();
    product.amount = 1;

    tempCart.products.push(shop);

    tempCart.products
      .find((store) => store.shopID === shop.shopID)
      .products.push(product);

    this.cart.next(tempCart);
  }

  private deleteShopAndProductFromCache(shop, product) {
    const tempCart = this.cart.getValue();
    product.amount = 0;

    tempCart.products = tempCart.products.filter(
      (store) => store.shopID !== shop.shopID,
    );

    this.cart.next(tempCart);
  }

  private deleteProductFromCache(shop, product) {
    const tempCart = this.cart.getValue();
    product.amount = 0;

    const productsShop = tempCart.products.find(
      (store) => store.shopID === shop.shopID,
    );

    productsShop.products = productsShop.products.filter(
      (prod) => prod.productShopRelationID !== product.productShopRelationID,
    );

    this.cart.next(tempCart);
  }

  public setCachedTotal(total: number) {
    const tempCart = this.cart.getValue();
    tempCart.basketTotal = total;
    this.cart.next(tempCart);
  }

  public countCacheTotal(): number {
    const tempCart = this.cart.getValue();
    let total = 0;

    tempCart.products.forEach((shop) =>
      shop.products.forEach(
        (product) => (total += product.offerPrice * product.amount),
      ),
    );

    return Number(total.toFixed(2));
  }
}
