import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProductModalService } from '../../shared/services/product-modal.service';
import { SessionService } from '../../shared/services/session.service';
import { CartService } from '../../shared/services/cart.service';
import { PopupService } from '../../shared/services/popup.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
})
export class ProductModalComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper') private container: ElementRef;
  public isAdded = false;

  public center = [0, 0];
  public locations = [];
  public product = null;
  public shop = null;

  public composition = true;
  public description = true;
  public info = true;

  private date;
  private now = Date.now();
  public hours;
  public days;

  constructor(
    public productModal: ProductModalService,
    private changeDetector: ChangeDetectorRef,
    private sessionService: SessionService,
    private cartService: CartService,
    private popupService: PopupService,
  ) {}

  ngOnInit() {
    this.productModal.product$.subscribe((product) => {
      this.product = product;

      if (this.product !== null) {
        this.parseLocations();
        this.calculateCenter();
        this.changeDetector.detectChanges();

        if (this.product.flierActiveTill == null) {
          this.days = null;
        } else {
          this.date = dayjs(
            this.product.flierActiveTill,
            'YYYY-MM-DD hh:mm:ss',
          ).valueOf();

          this.hours = Math.ceil((this.date - this.now) / (1000 * 60 * 60));
          this.days = Math.ceil(this.hours / 24);
        }
      }
    });
    this.productModal.shop$.subscribe((shop) => {
      this.shop = shop;
    });
    this.productModal.isAdded$.subscribe((isAdded) => {
      this.isAdded = isAdded;
    });
  }

  ngAfterViewInit(): void {
    this.productModal.isOpened$.subscribe(() =>
      this.container?.nativeElement?.scrollTo(0, 0),
    );
  }

  private declOfNum(num: number, titles: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
    ];
  }

  public parseLocations() {
    this.locations = this.product.shops.map((shop) => {
      return {
        name: shop.name,
        address: shop.address,
        workingHours: shop.workingHours,
        location: shop.geoLocation.longitude.split(',').map((str) => +str),
      };
    });
  }

  public calculateCenter() {
    const sum = (loc1, loc2) => [loc1[0] + loc2[0], loc1[1] + loc2[1]];
    this.center = this.locations
      .reduce((acc, location) => sum(acc, location.location), [0, 0])
      .map((coord) => coord / this.locations.length);
  }

  public closeModal(event: Event): void {
    if (event.target === event.currentTarget) {
      this.productModal.close();
    }
  }

  public add() {
    if (!this.sessionService.isAuthenticated()) {
      this.popupService.show();
    } else {
      const product = {
        name: this.product.name,
        productImageURL: this.product.imageURL,
        offerPrice: this.product.offerPrice,
        productShopRelationID: this.product.productShopRelationID,
      };
      this.cartService.add(this.shop, product).subscribe();
      this.isAdded = !this.isAdded;
    }
  }

  public delete() {
    if (!this.sessionService.isAuthenticated()) {
      this.popupService.show();
    } else {
      this.cartService.deleteProduct(this.shop, this.product).subscribe();
      this.isAdded = !this.isAdded;
    }
  }
}
