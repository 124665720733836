<section class="search">
  <div class="search-form">
    <i class="search-form__icon search-icon" (click)="openSearchForm()"></i>
    <input
      #inputElement
      class="search-form__input"
      placeholder="Поиск по магазину"
      spellcheck="false"
      (keydown.enter)="searchPage()"
    />

    <div
      *ngIf="inputElement.value && suggestions.length > 0"
      class="search-form__suggestions-wrapper"
    >
      <suggestions
        [suggestions]="suggestions"
        (selectEvent)="setSuggestion($event)"
      ></suggestions>
    </div>
  </div>
</section>
