<div
  class="background-shadow"
  (click)="hidePopup()"
  [class.background-shadow--toggled]="popup.toggled$ | async"
>
  <section class="popup" [class.popup--toggled]="popup.toggled$ | async">
    <p class="popup__message">
      Авторизуйтесь, чтобы не потерять сохраненные товары, сети и списки
    </p>
    <a
      class="popup__button button button--fluid"
      (click)="hidePopup()"
      href="/auth"
      routerLink="/auth"
      >Авторизоваться</a
    >
  </section>
</div>
