<div class="container">
  <banner-carousel [banners]="banners"></banner-carousel>

  <ng-container *ngIf="comparedStores?.products?.length">
    <div class="titles">
      <h2 class="subtitle">
        🍍
        <a
          [href]="'/store/' + comparedStores.firstStoreId"
          [routerLink]="['/store/', comparedStores.firstStoreId]"
          class="hl"
        >
          <span class="hl__text">{{ comparedStores.firstName }}</span>
        </a>
        и
        <a
          [href]="'/store/' + comparedStores.secondStoreId"
          [routerLink]="['/store/', comparedStores.secondStoreId]"
          class="hl hl--orange"
        >
          <span class="hl__text">{{ comparedStores.secondName }}</span>
        </a>
      </h2>
      <a
        class="view-all"
        href="/store-comparison"
        routerLink="/store-comparison"
        >Посмотреть все</a
      >
    </div>

    <div class="comparison-cards__updated-wrapper">
      <span
        *ngIf="comparisonUpdated !== null"
        class="comparison-cards__updated"
        >{{ comparisonUpdated }}</span
      >
    </div>

    <carousel>
      <div
        *ngFor="
          let ia of times(comparedStores.products.length / 4);
          let i = index
        "
        class="carousel-cell comparison-cards"
        #cell
      >
        <!-- TODO: Think about better implementation -->
        <div
          *ngFor="let ja of times(4); let j = index"
          class="comparison-cards__card"
        >
          <comparison-card
            [firstProduct]="
              comparedStores.products[i * 4 + j] &&
              comparedStores.products[i * 4 + j][0]
            "
            [secondProduct]="
              comparedStores.products[i * 4 + j] &&
              comparedStores.products[i * 4 + j][1]
            "
            [firstShopId]="comparedStores.firstStoreId"
            [secondShopId]="comparedStores.secondStoreId"
          ></comparison-card>
        </div>
      </div>
    </carousel>
    <a
      href="/store-comparison"
      routerLink="/store-comparison"
      class="button-view-all button button--fluid button--outline"
      >Посмотреть все</a
    >
  </ng-container>

  <div class="titles">
    <h2 class="subtitle">
      <a href="/stores" routerLink="/stores"
        >Скидки сетей ({{ stores.length }})</a
      >
    </h2>
    <a class="view-all" href="/stores" routerLink="/stores">Посмотреть все</a>
  </div>
  <carousel>
    <div *ngFor="let store of stores" #cell class="carousel-cell store-card">
      <store-card [shop]="store"></store-card>
    </div>
  </carousel>
  <a
    href="/stores"
    routerLink="/stores"
    class="button-view-all button button--fluid button--outline"
    >Посмотреть все</a
  >

  <div class="titles">
    <h2 class="subtitle">
      <a href="/categories" routerLink="/categories"
        >Категории ({{ categories.length }})</a
      >
    </h2>
    <a class="view-all" href="/categories" routerLink="/categories"
      >Посмотреть все</a
    >
  </div>
  <carousel>
    <div
      *ngFor="let category of categories"
      #cell
      class="carousel-cell category-card"
    >
      <category-card [category]="category"></category-card>
    </div>
  </carousel>
  <a
    href="/categories"
    routerLink="/categories"
    class="button-view-all button button--fluid button--outline"
  >
    Посмотреть все
  </a>

  <div class="titles">
    <h2 class="subtitle">
      <a href="/popular" routerLink="/popular"
        >Популярные товары ({{ popular.length }})</a
      >
    </h2>
    <a class="view-all" href="/popular" routerLink="/popular">
      Посмотреть все
    </a>
  </div>
  <ngx-masonry [options]="masonryOptions" [ordered]="true">
    <div
      ngxMasonryItem
      class="offers-card"
      *ngFor="let product of popular.slice(0, 7)"
    >
      <offers-card [product]="product"></offers-card>
    </div>
  </ngx-masonry>
  <a
    href="/popular"
    routerLink="/popular"
    class="button-view-all button button--fluid button--outline"
  >
    Посмотреть все
  </a>
</div>
