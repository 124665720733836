import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
} from '@angular/common/http';
import { interval, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  readonly REFRESH_INTERVAL = 10 * 60 * 1000;

  constructor(
    private session: SessionService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    if (isPlatformBrowser(platformId)) {
      interval(this.REFRESH_INTERVAL)
        .pipe(
          filter(() => !!this.session.getUser()?.userId),
          switchMap(() => this.session.refreshToken()),
        )
        .subscribe({
          error: (err) => {
            this.session.logout();
            location.reload();
          },
        });
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && isPlatformBrowser(this.platformId)) {
          this.session.logout();
          location.reload();
        } else {
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
      }),
    );
  }
}
