import { Component, Input, OnInit } from '@angular/core';
import { BannerModel } from '../../../shared/models/banner.model';
import { BannerAction } from '../../../shared/enums/banner-action.enum';
import { Router } from '@angular/router';
import { Paths } from '../../../shared/enums/paths.enum';
import { ProductModalService } from '../../../shared/services/product-modal.service';

@Component({
  selector: 'banner-card',
  templateUrl: './banner-card.component.html',
  styleUrls: ['./banner-card.component.scss'],
})
export class BannerCardComponent implements OnInit {
  @Input() public banner: BannerModel;

  constructor(
    private router: Router,
    private productModal: ProductModalService,
  ) {}

  ngOnInit(): void {}

  public async clickHandler() {
    switch (this.banner.action) {
      case BannerAction.DIRECT_LINK: {
        location.replace(this.banner.url);
        break;
      }
      case BannerAction.CATEGORY_LINK: {
        await this.router.navigate(['/', Paths.CATEGORY, this.banner.entityID]);
        break;
      }
      // TODO: Fix issue with modal
      case BannerAction.PRODUCT_LINK: {
        this.productModal.open(this.banner.entityID, 1);
        break;
      }
      case BannerAction.STORE_LINK: {
        await this.router.navigate(['/', Paths.STORE, this.banner.entityID]);
        break;
      }
    }
  }
}
