import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { StoresComponent } from './pages/stores/stores.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { StoreComponent } from './pages/store/store.component';
import { CategoryComponent } from './pages/category/category.component';
import { AuthComponent } from './pages/auth/auth.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeResolver } from './pages/home/home.resolver';
import { CategoriesResolver } from './pages/categories/categories.resolver';
import { StoresResolver } from './pages/stores/stores.resolver';
import { StoreResolver } from './pages/store/store.resolver';
import { Paths } from './shared/enums/paths.enum';
import { ComparisonComponent } from './pages/comparison/comparison.component';
import { ComparisonResolver } from './pages/comparison/comparison.resolver';
import { FlyerComponent } from './pages/flyer/flyer.component';
import { FlyerResolver } from './pages/flyer/flyer.resolver';
import { CategoryResolver } from './pages/category/category.resolver';
import { PopularComponent } from './pages/popular/popular.component';
import { PopularResolver } from './pages/popular/popular.resolver';
import { SearchComponent } from './pages/search/search.component';
import { SearchResolver } from './pages/search/search.resolver';
import { ProfileAllComponent } from './pages/profile/all/profile-all.component';
import { WatchedProductsComponent } from './pages/profile/watched-products/watched-products.component';
import { WatchedStoresComponent } from './pages/profile/watched-stores/watched-stores.component';
import { ProfileResolver } from './pages/profile/profile.resolver';
import { AuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {
      data: HomeResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: Paths.HOME,
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: Paths.STORES,
    component: StoresComponent,
    resolve: {
      data: StoresResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: `${Paths.STORE}/:id`,
    component: StoreComponent,
    resolve: {
      data: StoreResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: Paths.CATEGORIES,
    component: CategoriesComponent,
    resolve: {
      data: CategoriesResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: `${Paths.CATEGORY}/:id`,
    component: CategoryComponent,
    resolve: {
      data: CategoryResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: Paths.STORE_COMPARISON,
    component: ComparisonComponent,
    resolve: {
      data: ComparisonResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: Paths.PROFILE,
    component: ProfileComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: ProfileResolver,
    },
    children: [
      {
        path: '',
        redirectTo: `/${Paths.PROFILE}/${Paths.PROFILE_ALL}`,
        pathMatch: 'full',
      },
      {
        path: Paths.PROFILE_ALL,
        component: ProfileAllComponent,
      },
      {
        path: Paths.PROFILE_WATCH_PRODUCTS,
        component: WatchedProductsComponent,
      },
      {
        path: Paths.PROFILE_WATCH_STORES,
        component: WatchedStoresComponent,
      },
    ],
  },
  {
    path: Paths.AUTH,
    component: AuthComponent,
  },
  {
    path: `${Paths.STORE}/:id/${Paths.FLYER}`,
    component: FlyerComponent,
    resolve: {
      data: FlyerResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: Paths.POPULAR,
    component: PopularComponent,
    resolve: {
      data: PopularResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: `${Paths.SEARCH}/:query`,
    component: SearchComponent,
    resolve: {
      data: SearchResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
