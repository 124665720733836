<div
  class="container"
  infiniteScroll
  [infiniteScrollDistance]="3"
  (scrolled)="scrollDown()"
  [fromRoot]="true"
>
  <div class="breadcrumb">
    <a href="/home" routerLink="/home">Главная</a> /
    <a href="/popular" routerLink="/popular">Популярные товары</a>
  </div>
  <h2 class="subtitle">Популярные товары ({{ popular.length }})</h2>
  <ngx-masonry [options]="masonryOptions" [ordered]="true">
    <div
      ngxMasonryItem
      class="offers-card"
      *ngFor="let product of popular.slice(0, max)"
    >
      <offers-card [product]="product"></offers-card>
    </div>
  </ngx-masonry>
</div>
