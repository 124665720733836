import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { ShopModel } from '../../shared/models/shop.model';
import { CategoryModel } from '../../shared/models/category.model';
import { StoreService } from '../../shared/services/store.service';
import { BannerService } from '../../shared/services/banner.service';
import { WatchListService } from '../../shared/services/watch-list.service';

interface StoresResolvingData {
  stores: ShopModel[];
  categories: CategoryModel[];
}

@Injectable({ providedIn: 'root' })
export class StoresResolver implements Resolve<StoresResolvingData> {
  constructor(
    private storeService: StoreService,
    private bannerService: BannerService,
    private watchListService: WatchListService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return forkJoin({
      stores: this.storeService.getStores(),
      banners: this.bannerService.getBanners(),
      watchedStores: this.watchListService.preloadStores(),
    });
  }
}
