import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { SearchService as SearchesService } from '../../shared/services/search.service';
import { SearchService } from './search.service';
import { CartService } from '../../shared/services/cart.service';

@Injectable({ providedIn: 'root' })
export class SearchResolver implements Resolve<any> {
  constructor(
    private searchesService: SearchesService,
    private searchService: SearchService,
    private cartService: CartService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    const query: string = route.paramMap.get('query');
    return forkJoin({
      products: this.searchesService.searchInCity(
        query,
        1,
        this.searchService.sortBy,
      ),
      cart: this.cartService.preloadCart(),
    });
  }
}
