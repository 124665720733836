import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.scss'],
})
export class BannerCarouselComponent implements OnInit {
  // TODO: Change any to model type
  @Input() public banners: any[];

  constructor() {}

  ngOnInit(): void {}
}
