import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BannerModel } from '../models/banner.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: HttpClient) {}

  public getBanners(): Observable<BannerModel[]> {
    return this.http.get('/api/v2/banner/main').pipe(
      map((json: any) => {
        return json.result;
      }),
    );
  }
}
