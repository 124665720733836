import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../shared/services/session.service';
import { Router } from '@angular/router';
import { Paths } from '../../../shared/enums/paths.enum';
import { PathsRus } from '../../../shared/enums/paths-rus.enum';

@Component({
  selector: 'profile-all',
  templateUrl: './profile-all.component.html',
  styleUrls: ['./profile-all.component.scss'],
})
export class ProfileAllComponent implements OnInit {
  public readonly paths = Paths;
  public readonly pathsRus = PathsRus;

  constructor(public session: SessionService, private router: Router) {}

  ngOnInit(): void {}

  public async logout() {
    this.session.logout();
    await this.router.navigate(['/']);
  }

  public async onToggle(event) {
    switch (event) {
      case 0:
        await this.router.navigate([
          '/',
          this.paths.PROFILE,
          this.paths.PROFILE_ALL,
        ]);
        break;
      case 1:
        await this.router.navigate([
          '/',
          this.paths.PROFILE,
          this.paths.PROFILE_WATCH_PRODUCTS,
        ]);
        break;
      case 2:
        await this.router.navigate([
          '/',
          this.paths.PROFILE,
          this.paths.PROFILE_WATCH_STORES,
        ]);
        break;
    }
  }
}
