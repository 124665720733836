import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CityService } from './city.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CategoryModel } from '../models/category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient, private cityService: CityService) {}

  public getAllCategories(): Observable<CategoryModel[]> {
    return this.http
      .get(`/api/v2/city/${this.cityService.getId()}/categories`)
      .pipe(
        map((json: any) => json.result),
        map((categories) => categories.sort((a, b) => a.order - b.order)),
      );
  }

  // TODO: Change any to model type
  public getStoreCategories(storeId: number): Observable<any[]> {
    return this.http
      .get<any>(`/api/v2/shop/${storeId}/categories`)
      .pipe(map((json) => json.result));
  }
}
