export enum Paths {
  HOME = 'home',
  STORES = 'stores',
  STORE = 'store',
  CATEGORIES = 'categories',
  CATEGORY = 'category',
  PROFILE = 'profile',
  PROFILE_ALL = 'all',
  PROFILE_WATCH_PRODUCTS = 'products',
  PROFILE_WATCH_STORES = 'stores',
  AUTH = 'auth',
  STORE_COMPARISON = 'store-comparison',
  FLYER = 'flyer',
  POPULAR = 'popular',
  SEARCH = 'search',
}
