import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  QueryList,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @ViewChild('container')
  private container: ElementRef;

  @ContentChildren('cell')
  private cells: QueryList<ElementRef>;

  public unitsToScrollBy = 300;
  private scrollLeft = 0;
  private scrollRight = 0;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.unitsToScrollBy =
      this.container.nativeElement.scrollWidth / this.cells.length;
    this.onScrollHandler();
    this.changeDetector.detectChanges();
  }

  public scrollHorizontallyBy(scrollValue: number): void {
    this.container.nativeElement.scrollBy({
      top: 0,
      left: scrollValue,
      behavior: 'smooth',
    });
  }

  public isFirstSlide(): boolean {
    // TODO: Problem with rems, js doesn't calculate correctly pixel values therefore producing bug with right arrow button
    return this.scrollLeft <= 10;
  }

  public isLastSlide(): boolean {
    // TODO: Problem with rems, js doesn't calculate correctly pixel values therefore producing bug with right arrow button
    return this.scrollRight <= 10;
  }

  public onScrollHandler(): void {
    const { nativeElement: container } = this.container;

    this.scrollLeft = container.scrollLeft;
    this.scrollRight =
      container.scrollWidth - container.scrollLeft - container.clientWidth;
  }
}
