import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreComponent } from './store.component';
import { ProductCardModule } from '../../domain/product-card/product-card.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SelectModule } from '../../domain/select/select.module';
import { RouterModule } from '@angular/router';
import { StoreService } from './store.service';
import { TabGroupModule } from '../../domain/tab-group/tab-group.module';

@NgModule({
  declarations: [StoreComponent],
  imports: [
    CommonModule,
    ProductCardModule,
    InfiniteScrollModule,
    TabGroupModule,
    SelectModule,
    RouterModule,
  ],
  exports: [StoreComponent],
  providers: [StoreService],
})
export class StoreModule {}
