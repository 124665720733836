import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryModel } from '../../shared/models/category.model';
import { ProductsService } from '../../shared/services/products.service';
import { SortBy } from '../../shared/enums/sort-by.enum';
import { CategoryService } from './category.service';

@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  public currentCategory: CategoryModel;
  public categories: CategoryModel[];
  public products: any[];
  public page = 1;

  public selectOptions = [
    { name: 'По популярности', sortBy: SortBy.POPULAR },
    { name: 'По скидке', sortBy: SortBy.DISCOUNT },
    { name: 'По цене', sortBy: SortBy.PRICE },
  ];
  public defaultOption = this.selectOptions[0];

  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private categoryService: CategoryService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.page = 1;

      const categoryId = +this.route.snapshot.paramMap.get('id');
      const { categories, products } = data.data;

      this.categories = categories;
      this.products = products;
      this.currentCategory = this.categories.find(
        (category) => category.ID === categoryId,
      );
    });
  }

  public scrollDown() {
    this.page += 1;
    this.productsService
      .getProductsByCategory(
        this.currentCategory.ID,
        this.page,
        this.categoryService.sortBy,
      )
      .subscribe(
        (products) => (this.products = this.products.concat(products)),
      );
  }

  public async selectOption(option) {
    this.categoryService.sortBy = option.sortBy;
    await this.router.navigate([location.pathname]);
  }

  public async onToggle(index: number) {
    await this.router.navigate(['/category', this.categories[index].ID]);
  }
}
