<section class="clicker">
  <ng-container *ngIf="isClickerActive">
    <button (click)="delete($event)" class="clicker__minus-btn">
      <span class="minus-icon"></span>
    </button>
    <span class="clicker__amount">{{ product.amount }}</span>
  </ng-container>

  <button (click)="add($event)" class="clicker__add-btn">
    <span class="add-icon"></span>
  </button>
</section>
