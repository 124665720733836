<div class="select" (window:click)="closeSelectDropdown()">
  <div class="select__selected-option" (click)="toggleSelectDropdown()">
    <ng-container *ngIf="!!selected; else placeholderTemplate">
      <ng-template
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ $implicit: selected }"
      ></ng-template>
    </ng-container>

    <ng-template #placeholderTemplate>
      {{ placeholder }}
    </ng-template>

    <i class="select__icon arrow-bottom-icon"></i>
  </div>
  <ul *ngIf="itemsVisible" class="select__content">
    <li
      *ngFor="let option of options"
      (click)="selectItem(option)"
      class="select__option"
    >
      <ng-template
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ $implicit: option }"
      ></ng-template>
    </li>
  </ul>
</div>
