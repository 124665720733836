import { Component, OnInit } from '@angular/core';
import { Paths } from '../../shared/enums/paths.enum';
import { PathsRus } from '../../shared/enums/paths-rus.enum';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public readonly paths = Paths;
  public readonly pathsRus = PathsRus;

  ngOnInit(): void {}
}
