<div class="container">
  <banner-carousel [banners]="banners"></banner-carousel>

  <div class="breadcrumb">
    <a href="/home" routerLink="/home">Главная</a> /
    <a href="/stores" routerLink="/stores">Каталог</a>
  </div>

  <h2 class="subtitle">Скидки сетей ({{ stores.length }})</h2>
  <div class="stores">
    <div *ngFor="let store of stores" class="stores__card">
      <store-card [shop]="store"></store-card>
    </div>
  </div>
</div>
