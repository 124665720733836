import { Component, OnInit } from '@angular/core';
import { Paths } from '../../../shared/enums/paths.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public readonly paths = Paths;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public async openProfile() {
    await this.router.navigate([`/${this.paths.PROFILE}`]);
  }
}
