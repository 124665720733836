<section class="cart-btn" (click)="openCart()">
  <ng-container
    *ngIf="
      sessionService.isAuthenticated() && data$ | async as data;
      else elseBlock
    "
  >
    <span class="cart-btn__total">{{ data.basketTotal }} P</span>
    <div class="cart-btn__cart">
      <i class="cart-btn__icon cart-icon"></i>
      <span class="cart-btn__amount">{{ countProducts(data.products) }}</span>
    </div>
  </ng-container>

  <ng-template #elseBlock>
    <div class="cart-btn__cart">
      <i class="cart-btn__icon cart-icon"></i>
    </div>
  </ng-template>
</section>

<section (click)="openCart()" class="cart-icon-mobile">
  <i class="list-mobile-icon"></i>
  <span class="">Список</span>
</section>

<section #cart class="cart">
  <div class="cart__header cart-header">
    <span class="cart-header__title">Корзина</span>
    <button (click)="closeCart()" class="cart-header__close-btn">
      <i class="close-cross-icon"></i>
    </button>
  </div>

  <ng-container *ngIf="data$ | async as data">
    <div *ngFor="let shop of data.products" class="cart__store store">
      <shop [shop]="shop"></shop>
    </div>

    <button
      *ngIf="data.basketTotal !== 0; else cartEmpty"
      class="cart__total-btn total-btn"
    >
      <span class="total-btn__title">Итого</span
      ><span class="total-btn__total">{{ data.basketTotal }} P</span>
    </button>

    <ng-template #cartEmpty>
      <span class="total-btn__empty">В корзине пусто</span>
    </ng-template>
  </ng-container>
</section>

<div *ngIf="isCartOpened" (click)="closeCart()" class="background-shadow"></div>
