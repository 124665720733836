import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { CategoryService as CategoriesService } from '../../shared/services/category.service';
import { ProductsService } from '../../shared/services/products.service';
import { CategoryService } from './category.service';
import { WatchListService } from '../../shared/services/watch-list.service';
import { CartService } from '../../shared/services/cart.service';

@Injectable({ providedIn: 'root' })
export class CategoryResolver implements Resolve<any> {
  constructor(
    private categoriesService: CategoriesService,
    private products: ProductsService,
    private categoryService: CategoryService,
    private watchListService: WatchListService,
    private cartService: CartService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    const categoryId = +route.paramMap.get('id');
    return forkJoin({
      categories: this.categoriesService.getAllCategories(),
      products: this.products.getProductsByCategory(
        categoryId,
        1,
        this.categoryService.sortBy,
      ),
      watchedProducts: this.watchListService.preloadProducts(),
      cart: this.cartService.preloadCart(),
    });
  }
}
