<section>
  <div class="store__header store-header" (click)="toggleShop()">
    <div class="store-header__left">
      <span class="store-header__title">{{ shop.shopName }}</span>
      <div class="store-header__btn-wrapper">
        <button
          class="store-header__btn arrow-top-icon"
          [ngClass]="{
            'arrow-top-icon': isShown === false,
            'arrow-bottom-icon': isShown === true
          }"
        ></button>
      </div>
    </div>

    <span class="store-header__total">{{ getShopTotal(shop) }} Р</span>
  </div>

  <ng-container *ngIf="isShown">
    <div *ngFor="let product of shop.products" class="store__item store-item">
      <div class="store-item__img-wrapper">
        <img
          class="store-item__img"
          src="/assets/images/preload.png"
          [lazyLoad]="getImageUrl(product)"
        />
      </div>
      <div class="store-item__info">
        <span class="store-item__title">{{ product.name }}</span>
        <span class="store-item__total"
          >{{ product.offerPrice || product.regularPrice }} Р</span
        >
      </div>

      <div class="store-item__clicker-wrapper">
        <clicker
          [shop]="shop"
          [product]="product"
          class="product-card__clicker"
        ></clicker>
      </div>
    </div>
  </ng-container>
</section>
