<div class="tab-group">
  <ng-container *ngFor="let option of options; let i = index">
    <div
      *ngIf="i <= visibleCount || optionsVisible"
      [class.option--toggled]="defaultToggledIndex === i"
      (click)="onToggle(i)"
      class="option"
    >
      <ng-template [ngTemplateOutlet]="option"></ng-template>
    </div>
  </ng-container>

  <div
    class="tab-group__show-more"
    *ngIf="notVisibleOptionsCount > 0"
    (click)="optionsVisible ? hideOptions() : showOptions()"
  >
    {{ optionsVisible ? 'Скрыть' : '+' + notVisibleOptionsCount }}
  </div>
</div>
