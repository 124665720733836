import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements OnInit {
  private focus = true;

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.focus) {
        window.setTimeout(() => {
          this.el.nativeElement.focus();
        });
      }
    }
  }

  @Input() set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}
