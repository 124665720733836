<div
  class="container"
  infiniteScroll
  [infiniteScrollDistance]="3"
  (scrolled)="scrollDown()"
>
  <div class="breadcrumb">
    <a href="/home" routerLink="/home">Главная</a> /
    <a href="/categories" routerLink="/categories">Каталог</a> /
    <a>{{ currentCategory.categoryName }}</a>
  </div>

  <h1 class="title">{{ currentCategory.categoryName }}</h1>

  <div class="tab-group">
    <tab-group
      [defaultToggledIndex]="categories.indexOf(currentCategory)"
      (toggle)="onToggle($event)"
    >
      <ng-template #option *ngFor="let category of categories">
        {{ category.categoryName }}
      </ng-template>
    </tab-group>
  </div>

  <div class="select">
    <app-select
      [options]="selectOptions"
      [selected]="defaultOption"
      (select)="selectOption($event)"
    >
      <ng-template let-option>
        <div>{{ option.name }}</div>
      </ng-template>
    </app-select>
  </div>

  <ngx-masonry [options]="{ gutter: 16 }" [ordered]="true">
    <div ngxMasonryItem class="offer-card" *ngFor="let product of products">
      <offers-card [product]="product"></offers-card>
    </div>
  </ngx-masonry>
</div>
