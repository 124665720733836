import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { StoreService } from '../../shared/services/store.service';

@Injectable({ providedIn: 'root' })
export class FlyerResolver implements Resolve<any> {
  constructor(private storeService: StoreService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    const storeId = +route.paramMap.get('id');
    return forkJoin({
      flyers: this.storeService.getStoresFlyer(storeId),
      store: this.storeService.getStoreById(storeId),
    });
  }
}
