import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelatedProductCardComponent } from './related-product-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [RelatedProductCardComponent],
  imports: [CommonModule, LazyLoadImageModule],
  exports: [RelatedProductCardComponent],
})
export class RelatedProductCardModule {}
