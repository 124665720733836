import { Component, HostListener, OnInit } from '@angular/core';
import { CityService } from '../../../shared/services/city.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { WindowScrollingService } from '../../../shared/services/window-scrolling.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'location-dropdown',
  templateUrl: './location-dropdown.component.html',
  styleUrls: ['./location-dropdown.component.scss'],
})
export class LocationDropdownComponent implements OnInit {
  public isOpened = false;
  public isFormOpened = false;
  private url: string;

  constructor(
    public cityService: CityService,
    private router: Router,
    private windowScrolling: WindowScrollingService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        this.url = event.url;
      });
  }

  // TODO: Change any to model type
  public async changeCity(city: any) {
    this.closeDropdown();
    this.cityService.changeCity(city);
    if (this.url.includes('search')) {
      await this.router.navigate(['/search', this.getQueryFromUrl(this.url)]);
    } else {
      await this.router.navigate([location.pathname]);
    }
  }

  public openDropdown() {
    this.isOpened = true;
    this.windowScrolling.disable();
  }

  public openForm() {
    this.isOpened = false;
    this.isFormOpened = true;
  }

  private closeDropdown() {
    this.isOpened = false;
    this.isFormOpened = false;
    this.windowScrolling.enable();
  }

  private getQueryFromUrl(url: string): string {
    const queryStart = url.lastIndexOf('/') + 1;
    const queryEnd = url.length;
    return decodeURIComponent(url.substring(queryStart, queryEnd));
  }
}
