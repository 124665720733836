import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private sessionService: SessionService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (isPlatformBrowser(this.platformId)) {
      const user = this.sessionService.getUser();

      if (request.url === '/api/v2/sessions/me/refresh') {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${user.refreshToken}`,
          },
        });
      } else if (
        !!this.sessionService.getUser() &&
        request.url !== '/api/v2/sessions'
      ) {
        const params = user.userId == null ? {} : { userToken: user.userId };
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          setParams: params,
        });
      }
    }

    return next.handle(request);
  }
}
