<div class="container">
  <div class="breadcrumb">
    <a href="/home" routerLink="/home">Главная</a> /
    <a href="/stores" routerLink="/stores">Каталог</a> /
    <a href="/store/{{ store.id }}}" routerLink="/store/{{ store.id }}">{{
      store.name
    }}</a>
  </div>

  <h1 class="title">{{ store.name }} ({{ store.productsCount }})</h1>

  <div class="tab-group">
    <tab-group (toggle)="onToggle($event)">
      <ng-template #option>Все</ng-template>
      <ng-template #option *ngFor="let category of categories">
        {{ category.categoryName }}
      </ng-template>
    </tab-group>
  </div>

  <div class="flyer">
    <div class="flyer__title">
      Бумажная листовка<br />магазина "{{ store.name }}"
    </div>
    <a
      class="flyer__button button"
      [routerLink]="['/store', store.id, 'flyer']"
      [href]="'/store/' + store.id + '/flyer'"
    >
      Смотреть
    </a>
  </div>

  <div class="select">
    <app-select
      [options]="selectOptions"
      [selected]="defaultOption"
      (select)="selectOption($event)"
    >
      <ng-template let-option>
        <div>{{ option.name }}</div>
      </ng-template>
    </app-select>
  </div>

  <div
    class="products"
    infiniteScroll
    [infiniteScrollDistance]="3"
    [fromRoot]="true"
    (scrolled)="scrollDown()"
  >
    <div *ngIf="products.length === 0">Нет продуктов</div>
    <div *ngFor="let product of products" class="products__card">
      <product-card [store]="store" [product]="product"></product-card>
    </div>
  </div>
</div>
