<div class="container">
  <ng-container *ngIf="!!comparedStores?.products?.length; else noProducts">
    <h1 class="title">Сравнение цен на рынках</h1>

    <h2 class="subtitle">
      🍍
      <a
        [href]="'/store/' + comparedStores.firstStoreId"
        [routerLink]="['/store/', comparedStores.firstStoreId]"
        class="hl"
      >
        <span class="hl__text">{{ comparedStores.firstName }}</span>
      </a>
      и
      <a
        [href]="'/store/' + comparedStores.secondStoreId"
        [routerLink]="['/store/', comparedStores.secondStoreId]"
        class="hl hl--orange"
      >
        <span class="hl__text">{{ comparedStores.secondName }}</span>
      </a>
    </h2>

    <div class="comparison">
      <div
        class="comparison__card"
        *ngFor="let comparedProduct of comparedStores.products"
      >
        <comparison-card
          [firstProduct]="comparedProduct[0]"
          [secondProduct]="comparedProduct[1]"
          [firstShopId]="comparedStores.firstStoreId"
          [secondShopId]="comparedStores.secondStoreId"
        ></comparison-card>
      </div>
    </div>
  </ng-container>

  <ng-template #noProducts>
    <h2 class="subtitle">Товаров не найдено</h2>
  </ng-template>
</div>
