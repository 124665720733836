import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CartService } from '../../../shared/services/cart.service';
import { filter, map } from 'rxjs/operators';
import { SessionService } from '../../../shared/services/session.service';
import { PopupService } from '../../../shared/services/popup.service';
import { WindowScrollingService } from '../../../shared/services/window-scrolling.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @ViewChild('cart', { static: false }) cartElement: ElementRef;
  public isCartOpened = false;
  public cartTotal: number;
  public data$;

  constructor(
    private cartService: CartService,
    public sessionService: SessionService,
    private popupService: PopupService,
    private windowScrolling: WindowScrollingService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.sessionService.isAuthenticated()) {
          this.data$ = this.cartService.getCart().pipe(
            filter((data) => data !== null),
            map((data) => {
              this.cartTotal = data.basketTotal;
              return data;
            }),
          );
        }
      }
    });
  }

  public openCart(): void {
    if (!this.sessionService.isAuthenticated()) {
      this.popupService.show();
    } else {
      this.data$ = this.cartService.getCart().pipe(
        filter((data) => data !== null),
        map((data) => {
          this.cartTotal = data.basketTotal;
          return data;
        }),
      );
      this.isCartOpened = true;
      this.cartElement.nativeElement.classList.toggle('cart--opened');
      this.windowScrolling.disable();
    }
  }

  public closeCart(): void {
    this.isCartOpened = false;
    this.cartElement.nativeElement.classList.toggle('cart--opened');
    this.windowScrolling.enable();
  }

  public countProducts(shops): number {
    let counter = 0;
    shops.forEach((shop) =>
      shop.products.forEach((product) => (counter += product.amount)),
    );
    return counter;
  }
}
