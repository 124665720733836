<div
  class="wrapper"
  #wrapper
  *ngIf="productModal.isOpened$ | async"
  (click)="closeModal($event)"
>
  <section class="modal" *ngIf="product">
    <button class="modal__close" (click)="productModal.close()">
      <i class="add-icon modal__close-icon"></i>
    </button>
    <div class="modal__top row">
      <img
        class="modal__image"
        defaultImage="assets/images/preload.png"
        [lazyLoad]="product.imageURL"
        [alt]="product.name"
      />
      <div class="column modal__product-info">
        <h2 class="modal__title">{{ product.name }}</h2>
        <div class="modal__prices">
          <div class="hl hl--rotated">
            <span class="hl__text"
              >{{
                product.offerPrice == null
                  ? product.regularPrice
                  : product.offerPrice
              }}р</span
            >
          </div>
          <div
            class="crossed-text"
            *ngIf="product.regularPrice != '0' && product.offerPrice != null"
          >
            {{ product.regularPrice }}р
          </div>
          <div class="modal__volume">{{ product.volume }}</div>
          <follow [obj]="product" class="modal__follow"></follow>
        </div>
        <!-- TODO: Change offer date -->
        <p class="modal__last-date" [style.opacity]="days != null ? 1 : 0">
          До конца акции осталось {{ days }}
          {{ declOfNum(days, ['день', 'дня', 'дней']) }}
        </p>
        <ng-container *ngIf="!isAdded">
          <button (click)="add()" class="button button--fluid">В список</button>
        </ng-container>
        <ng-container *ngIf="isAdded">
          <button (click)="delete()" class="button button--fluid">
            Убрать из списка
          </button>
        </ng-container>
      </div>
    </div>

    <h3 class="block-title" (click)="composition = !composition">
      Состав
      <i
        class="block-title__icon arrow-bottom-icon"
        [style.transform]="composition ? 'rotate(180deg)' : ''"
      ></i>
    </h3>
    <div class="block" *ngIf="composition">
      {{ product.composition || 'Магазин не предоставил информацию' }}
    </div>

    <h3 class="block-title" (click)="description = !description">
      Описание
      <i
        class="block-title__icon arrow-bottom-icon"
        [style.transform]="description ? 'rotate(180deg)' : ''"
      ></i>
    </h3>
    <div class="block" *ngIf="description">
      {{ product.description || 'Магазин не предоставил информацию' }}
    </div>

    <div class="row modal__general-info">
      <div>
        <h3 class="block-title" (click)="info = !info">
          Общая информация
          <i
            class="block-title__icon arrow-bottom-icon"
            [style.transform]="info ? 'rotate(180deg)' : ''"
          ></i>
        </h3>
        <div class="block" *ngIf="info">
          {{ product.info || 'Магазин не предоставил информацию' }}
        </div>
      </div>

      <div class="modal__map-wrapper">
        <h3 class="block-title">Где купить</h3>
        <div class="modal__map">
          <ya-map [center]="center" [zoom]="12">
            <ya-placemark
              *ngFor="let location of locations"
              [properties]="{
                balloonContentHeader: location.name,
                balloonContentBody: location.address,
                balloonContentFooter: location.workingHours
              }"
              [geometry]="location.location"
            ></ya-placemark>
          </ya-map>
        </div>
      </div>
    </div>

    <div class="subtitle">Похожие товары</div>
    <carousel>
      <div
        *ngFor="let relatedProduct of product.relatedProducts"
        class="carousel-cell related-product-card"
        #cell
      >
        <related-product-card [product]="relatedProduct"></related-product-card>
      </div>
    </carousel>
  </section>
</div>
