import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionService } from '../../shared/services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  public activeStepIndex = 1;
  public codeNumberInvalid = false;
  public phoneNumber: string;
  public smsDropdownVisible = false;

  public firstStepFormGroup: FormGroup = this.fb.group({
    phoneNumber: ['', [Validators.pattern(/^\d{9}/)]],
  });

  public secondStepFormGroup: FormGroup = this.fb.group({
    codeNumber: ['', Validators.pattern(/^\d{4}$/)],
  });

  constructor(
    private fb: FormBuilder,
    private sessionService: SessionService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  public getNewCode() {
    this.sessionService.sendPhoneNumber(this.phoneNumber).subscribe();
  }

  public onFirstStepSubmit(): void {
    if (this.firstStepFormGroup.valid) {
      const phonenumber = '+375' + this.firstStepFormGroup.value.phoneNumber;
      this.sessionService.sendPhoneNumber(phonenumber).subscribe(() => {
        this.phoneNumber = phonenumber;
        this.activeStepIndex = 2;
      });
    }
  }

  public onCodeNumberInput(input: string): void {
    if (input.length === 4) {
      this.sessionService.sendConfirmationCode(input).subscribe(
        () => this.router.navigate(['/']),
        () => (this.codeNumberInvalid = true),
      );
    }
  }
}
