import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortBy } from '../../shared/enums/sort-by.enum';
import { SearchService as SearchesService } from '../../shared/services/search.service';
import { SearchService } from './search.service';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public isLast: boolean;
  public products: any[];
  public page = 1;
  private query: string;

  public selectOptions = [
    { name: 'По популярности', sortBy: SortBy.POPULAR },
    { name: 'По скидке', sortBy: SortBy.DISCOUNT },
    { name: 'По цене', sortBy: SortBy.PRICE },
  ];
  public defaultOption = this.selectOptions[0];

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private searchesService: SearchesService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.page = 1;

      this.query = this.route.snapshot.paramMap.get('query');
      const { products } = data.data;
      this.isLast = products.isLast;
      this.products = products.products;
    });
  }

  public scrollDown() {
    if (!this.isLast) {
      this.page += 1;
      this.searchesService
        .searchInCity(this.query, this.page, this.searchService.sortBy)
        .subscribe((result) => {
          (this.isLast = result.isLast),
            (this.products = this.products.concat(result.products));
        });
    }
  }

  public async selectOption(option) {
    this.searchService.sortBy = option.sortBy;
    await this.router.navigate(['/search', this.query]);
  }

  public getDeclOfNum(num, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
    ];
  }

  public getFoundedWord(num): string {
    if (num === 1) {
      return 'Найден';
    }
    return 'Найдено';
  }
}
