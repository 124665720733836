<div class="tab-group">
  <tab-group
    (toggle)="onToggle($event)"
    [defaultToggledIndex]="1"
    [allVisible]="true"
  >
    <ng-template #option>Все</ng-template>
    <ng-template #option>Товары в слежу</ng-template>
    <ng-template #option>Магазины в слежу</ng-template>
  </tab-group>
</div>
<div>
  <ngx-masonry [options]="{ gutter: 16 }" [ordered]="true">
    <div ngxMasonryItem class="offer-card" *ngFor="let product of products">
      <offers-card [product]="product"></offers-card>
    </div>
  </ngx-masonry>
</div>
