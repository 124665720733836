import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  declarations: [DebounceClickDirective, AutofocusDirective],
  imports: [CommonModule],
  exports: [DebounceClickDirective, AutofocusDirective],
})
export class SharedModule {}
