import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PathsRus } from '../../shared/enums/paths-rus.enum';
import { Paths } from '../../shared/enums/paths.enum';

@Component({
  selector: 'flyer',
  templateUrl: './flyer.component.html',
  styleUrls: ['./flyer.component.scss'],
})
export class FlyerComponent implements OnInit {
  // TODO: Change any to model type
  public store: any;
  public flyers: any;
  public max = 2;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      const { store, flyers } = data.data;
      this.store = store;
      this.flyers = flyers;
    });
  }

  public scrollDown(): void {
    this.max += 1;
  }
}
