import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private toggledSubject = new BehaviorSubject<boolean>(false);

  public hide(): void {
    this.toggledSubject.next(false);
  }

  public show(): void {
    this.toggledSubject.next(true);
  }

  public get toggled$(): Observable<boolean> {
    return this.toggledSubject.asObservable();
  }
}
